import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const LabsEdit = () => {
  const dataTableRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [labs, setLabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editErrors, setEditErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});
  const [selectedLabId, setSelectedLabId] = useState(null);
  const [editLab, setEditLab] = useState({
    name: "",
    house_no: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [newLab, setNewLab] = useState({
    name: "",
    house_no: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const navigate = useNavigate(); // Initialize useNavigate
  const checkPermission = useCheckPermission();

  const canEditLab = checkPermission(ADMIN_MODULES.LABS, PERMISSIONS.EDIT);
  const canDeleteLab = checkPermission(ADMIN_MODULES.LABS, PERMISSIONS.DELETE);
  const canAddLab = checkPermission(ADMIN_MODULES.LABS, PERMISSIONS.ADD);


  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  // Fetch all labs on component mount
  useEffect(() => {
    const fetchLabs = async () => {
      try {
        const response = await axiosClient.get("labs");
        setLabs(response.data.labs);
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        // setError("Failed to fetch labs. Please try again.");
        setLoading(false);
      }
    };

    fetchLabs();
  }, []);

  // Handle showing the edit modal with selected lab data
  const handleShowEdit = (lab) => {
    setSelectedLabId(lab?._id);
    setEditLab({
      name: lab?.name || "",
      house_no: lab?.house_no || "",
      street: lab?.street || "",
      landmark: lab?.landmark || "",
      city: lab?.city || "",
      state: lab?.state || "",
      pincode: lab?.pincode || "",
    });
    setShowEditModal(true);
  };

  // Close the edit modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
  };

  // Update lab details
  const updateLab = async (e) => {
    e.preventDefault();
    if (!validateEditForm()) return;

    try {
      await axiosClient.put(`labs/${selectedLabId}`, editLab);

      // Update the labs list with the edited lab
      setLabs(
        labs.map((lab) =>
          lab._id === selectedLabId ? { ...lab, ...editLab } : lab
        )
      );

      setShowEditModal(false);
      setEditErrors({});
    } catch (err) {
      setError("Failed to update lab. Please try again.");
    }
  };

  // Show the delete confirmation modal
  const handleShowDelete = (labId) => {
    setSelectedLabId(labId);
    setShowDeleteModal(true);
  };

  // Helper function to format field names
  const formatFieldName = (fieldName) => {
    return fieldName
      .replace("_", " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Handle input changes in edit form with validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditLab((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate the specific field
    setEditErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (!value.trim()) {
        newErrors[name] = `${formatFieldName(name)} is required.`;
      } else if (
        ["name", "city", "state"].includes(name) &&
        !isAlphabetic(value)
      ) {
        newErrors[name] = `${formatFieldName(
          name
        )} should contain only letters and spaces.`;
      } else if (name === "pincode" && !/^\d{6}$/.test(value)) {
        newErrors.pincode = "Pincode must be a 6-digit number.";
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  // Close the delete modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Handle lab deletion
  const deleteLab = async () => {
    try {
      await axiosClient.delete(`labs/${selectedLabId}`);

      setLabs(labs.filter((lab) => lab._id !== selectedLabId));
      setShowDeleteModal(false);
    } catch (err) {
      setError("Failed to delete the lab. Please try again.");
      setShowDeleteModal(false);
    }
  };

  // Show Add Lab Modal
  const handleShowAdd = () => {
    setNewLab({
      name: "",
      house_no: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
    });
    setError("");
    setShowAddModal(true);
  };

  // Close Add Lab Modal
  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
  };

  // Handle input changes in Add Lab form with validation
  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setNewLab((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate the specific field
    setAddErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (!value.trim()) {
        newErrors[name] = `${formatFieldName(name)} is required.`;
      } else if (
        ["name", "city", "state"].includes(name) &&
        !isAlphabetic(value)
      ) {
        newErrors[name] = `${formatFieldName(
          name
        )} should contain only letters and spaces.`;
      } else if (name === "pincode" && !/^\d{6}$/.test(value)) {
        newErrors.pincode = "Pincode must be a 6-digit number.";
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  // Submit new lab
  const addLab = async (e) => {
    e.preventDefault();
    if (!validateAddForm()) return; // Stop if validation fails

    try {
      const response = await axiosClient.post("labs", newLab);
      const createdLab = response.data.lab;
      setLabs([...labs, createdLab]);
      setShowAddModal(false);
      setAddErrors({});
    } catch (err) {
      setError("Failed to add new lab. Please try again.");
    }
  };

  // Helper function to check if a string contains only letters and spaces
  const isAlphabetic = (value) => /^[A-Za-z\s]+$/.test(value);

  // Validation for Edit Form
  const validateEditForm = () => {
    const errors = {};

    // Name Validation
    if (!editLab.name.trim()) {
      errors.name = "Lab Name is required.";
    } else if (!isAlphabetic(editLab.name)) {
      errors.name = "Lab Name should contain only letters and spaces.";
    }

    // House No Validation
    if (!editLab.house_no.trim()) {
      errors.house_no = "House No is required.";
    }

    // Street Validation
    if (!editLab.street.trim()) {
      errors.street = "Street is required.";
    }

    // Landmark Validation
    if (!editLab.landmark.trim()) {
      errors.landmark = "Landmark is required.";
    }

    // City Validation
    if (!editLab.city.trim()) {
      errors.city = "City is required.";
    } else if (!isAlphabetic(editLab.city)) {
      errors.city = "City should contain only letters and spaces.";
    }

    // State Validation
    if (!editLab.state.trim()) {
      errors.state = "State is required.";
    } else if (!isAlphabetic(editLab.state)) {
      errors.state = "State should contain only letters and spaces.";
    }

    // Pincode Validation
    if (!/^\d{6}$/.test(editLab.pincode)) {
      errors.pincode = "Pincode must be a 6-digit number.";
    }

    setEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validation for Add Form
  const validateAddForm = () => {
    const errors = {};
    // Name Validation
    if (!newLab.name.trim()) {
      errors.name = "Lab Name is required.";
    } else if (!isAlphabetic(newLab.name)) {
      errors.name = "Lab Name should contain only letters and spaces.";
    }

    // House No Validation
    if (!newLab.house_no.trim()) {
      errors.house_no = "House No is required.";
    }

    // Street Validation
    if (!newLab.street.trim()) {
      errors.street = "Street is required.";
    }

    // Landmark Validation
    if (!newLab.landmark.trim()) {
      errors.landmark = "Landmark is required.";
    }

    // City Validation
    if (!newLab.city.trim()) {
      errors.city = "City is required.";
    } else if (!isAlphabetic(newLab.city)) {
      errors.city = "City should contain only letters and spaces.";
    }

    // State Validation
    if (!newLab.state.trim()) {
      errors.state = "State is required.";
    } else if (!isAlphabetic(newLab.state)) {
      errors.state = "State should contain only letters and spaces.";
    }

    // Pincode Validation
    if (!/^\d{6}$/.test(newLab.pincode)) {
      errors.pincode = "Pincode must be a 6-digit number.";
    }

    setAddErrors(errors);
    return Object.keys(errors).length === 0;
  };

  if (loading) return <p>Loading labs...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this lab?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteLab}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Lab Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateLab}>
              {error && <div className="alert alert-danger">{error}</div>}

              {/* Lab Name Field */}
              <div className="mb-3">
                <label className="form-label">Lab Name</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.name ? "is-invalid" : ""
                  }`}
                  name="name"
                  value={editLab.name}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.name && (
                  <div className="invalid-feedback">{editErrors.name}</div>
                )}
              </div>

              {/* House No Field */}
              <div className="mb-3">
                <label className="form-label">House No</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.house_no ? "is-invalid" : ""
                  }`}
                  name="house_no"
                  value={editLab.house_no}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.house_no && (
                  <div className="invalid-feedback">{editErrors.house_no}</div>
                )}
              </div>

              {/* Street Field */}
              <div className="mb-3">
                <label className="form-label">Street</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.street ? "is-invalid" : ""
                  }`}
                  name="street"
                  value={editLab.street}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.street && (
                  <div className="invalid-feedback">{editErrors.street}</div>
                )}
              </div>

              {/* Landmark Field */}
              <div className="mb-3">
                <label className="form-label">Landmark</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.landmark ? "is-invalid" : ""
                  }`}
                  name="landmark"
                  value={editLab.landmark}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.landmark && (
                  <div className="invalid-feedback">{editErrors.landmark}</div>
                )}
              </div>

              {/* City Field */}
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.city ? "is-invalid" : ""
                  }`}
                  name="city"
                  value={editLab.city}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.city && (
                  <div className="invalid-feedback">{editErrors.city}</div>
                )}
              </div>

              {/* State Field */}
              <div className="mb-3">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.state ? "is-invalid" : ""
                  }`}
                  name="state"
                  value={editLab.state}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.state && (
                  <div className="invalid-feedback">{editErrors.state}</div>
                )}
              </div>

              {/* Pincode Field */}
              <div className="mb-3">
                <label className="form-label">Pincode</label>
                <input
                  type="text"
                  className={`form-control ${
                    editErrors.pincode ? "is-invalid" : ""
                  }`}
                  name="pincode"
                  value={editLab.pincode}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.pincode && (
                  <div className="invalid-feedback">{editErrors.pincode}</div>
                )}
              </div>

              {/* Modal Footer Buttons */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Add Lab Modal */}
        <Modal show={showAddModal} onHide={handleCloseAdd} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addLab}>
              {error && <div className="alert alert-danger">{error}</div>}

              {/* Lab Name Field */}
              <div className="mb-3">
                <label className="form-label">Lab Name</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.name ? "is-invalid" : ""
                  }`}
                  name="name"
                  value={newLab.name}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.name && (
                  <div className="invalid-feedback">{addErrors.name}</div>
                )}
              </div>

              {/* House No Field */}
              <div className="mb-3">
                <label className="form-label">House No</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.house_no ? "is-invalid" : ""
                  }`}
                  name="house_no"
                  value={newLab.house_no}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.house_no && (
                  <div className="invalid-feedback">{addErrors.house_no}</div>
                )}
              </div>

              {/* Street Field */}
              <div className="mb-3">
                <label className="form-label">Street</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.street ? "is-invalid" : ""
                  }`}
                  name="street"
                  value={newLab.street}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.street && (
                  <div className="invalid-feedback">{addErrors.street}</div>
                )}
              </div>

              {/* Landmark Field */}
              <div className="mb-3">
                <label className="form-label">Landmark</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.landmark ? "is-invalid" : ""
                  }`}
                  name="landmark"
                  value={newLab.landmark}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.landmark && (
                  <div className="invalid-feedback">{addErrors.landmark}</div>
                )}
              </div>

              {/* City Field */}
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.city ? "is-invalid" : ""
                  }`}
                  name="city"
                  value={newLab.city}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.city && (
                  <div className="invalid-feedback">{addErrors.city}</div>
                )}
              </div>

              {/* State Field */}
              <div className="mb-3">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.state ? "is-invalid" : ""
                  }`}
                  name="state"
                  value={newLab.state}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.state && (
                  <div className="invalid-feedback">{addErrors.state}</div>
                )}
              </div>

              {/* Pincode Field */}
              <div className="mb-3">
                <label className="form-label">Pincode</label>
                <input
                  type="text"
                  className={`form-control ${
                    addErrors.pincode ? "is-invalid" : ""
                  }`}
                  name="pincode"
                  value={newLab.pincode}
                  onChange={handleAddInputChange}
                  required
                />
                {addErrors.pincode && (
                  <div className="invalid-feedback">{addErrors.pincode}</div>
                )}
              </div>

              {/* Modal Footer Buttons */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseAdd}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Add Lab
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            {canAddLab && <div className="heading-and-button">
              <h5>Labs Details</h5>
              <div className="add-button">
                <button onClick={handleShowAdd}>+ Add Lab</button>
              </div>
            </div>}
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>House No</th>
                      <th>Street</th>
                      <th>Landmark</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pincode</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labs.map((lab, index) => (
                      <tr key={lab._id}>
                        <td>{index + 1}</td>
                        <td>{lab.name}</td>
                        <td>{lab.house_no}</td>
                        <td>{lab.street}</td>
                        <td>{lab.landmark}</td>
                        <td>{lab.city}</td>
                        <td>{lab.state}</td>
                        <td>{lab.pincode}</td>
                        <td>
                          {canDeleteLab && <i
                            className="fa-solid fa-trash mr-2"
                            onClick={() => handleShowDelete(lab._id)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          ></i>}
                          {canEditLab && <i
                            onClick={() => handleShowEdit(lab)}
                            className="fa-solid fa-pen-to-square"
                            style={{ cursor: "pointer" }}
                          ></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LabsEdit;
