import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const NewsLetterEdit = () => {
  const dataTableRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const navigate = useNavigate();

  const checkPermission = useCheckPermission();

  const canDeleteNewsLetter = checkPermission(ADMIN_MODULES.NEWSLETTER, PERMISSIONS.DELETE);
  const canAddNewsLetter = checkPermission(ADMIN_MODULES.NEWSLETTER, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);
  // Fetch newsletters on component mount
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axiosClient.get("news");

        if (response.data.success) {
          setNewsList(response.data.news);
        } else {
          setError("Failed to fetch newsletters.");
        }
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        setError("Failed to fetch newsletters. Please try again.");
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // Handle closing of modals
  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setError("");
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
    setNewEmail("");
  };

  // Handle showing modals
  const handleShowDelete = (newsId) => {
    setSelectedNewsId(newsId);
    setShowDeleteModal(true);
  };

  const handleShowAdd = () => {
    setShowAddModal(true);
  };

  const handleAddInputChange = (e) => {
    const { value } = e.target;
    setNewEmail(value); // Always update the state

    // Validation for email
    const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,7}$/;
    if (value && !emailRegex.test(value)) {
      setError("Invalid email address.");
    } else {
      setError("");
    }
  };

  // Add new newsletter
  const addNews = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.post("news", { email: newEmail });
  
      if (response.status === 201) {
        console.log("ds", response);
        setNewsList([...newsList, response.data]);
        setShowAddModal(false);
        setNewEmail("");
      } else if (response.status === 400 && response.data.message === "Email is already subscribed") {
        // Manually throw an error to be caught in the catch block
        throw new Error("Email is already subscribed");
      } else {
        setError("Email is already subscribed.");
      }
    } catch (err) {
      if (err.message === "Email is already subscribed") {
        setError("Email is already subscribed. Please use a different email.");
      } else {
        setError("Email is already subscribed.");
      }
    }
  };
  

  // Delete newsletter
  const deleteNews = async () => {
    try {
      const response = await axiosClient.delete(`news/${selectedNewsId}`);
  
      if (response.data.success) {
        setShowDeleteModal(false);
        window.location.reload(); // Refresh the screen
      } else {
        setError("Failed to delete the newsletter. Please try again.");
        setShowDeleteModal(false);
      }
    } catch (err) {
      setError("Failed to delete the newsletter. Please try again.");
      setShowDeleteModal(false);
    }
  };
  

  if (loading) return <p>Loading newsletters...</p>;
  //   if (error) return <p className="text-danger">{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Newsletter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this newsletter?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteNews}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Add Newsletter Modal */}
        <Modal show={showAddModal} onHide={handleCloseAdd} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Newsletter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addNews}>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={newEmail}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseAdd}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Newsletter Subscribers</h5>
              {canAddNewsLetter && <div className="add-button" style={{ marginBottom: "20px" }}>
                <button className="btn btn-primary" onClick={handleShowAdd}>
                  Add Newsletter
                </button>
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Email</th>
                      <th>Subscribed On</th>
                     { canDeleteNewsLetter && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {newsList.map((news, index) =>
                      news && news.email ? (
                        <tr key={news._id}>
                          <td>{index + 1}</td>
                          <td>{news.email}</td>
                          <td>
                            {new Date(news.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </td>
                          {canDeleteNewsLetter && <td>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShowDelete(news._id)}
                              style={{ cursor: "pointer" }}
                              title="Delete Newsletter"
                            ></i>
                          </td>}
                        </tr>
                      ) : (
                        <tr key={index}>
                          <td colSpan="4" className="text-center text-danger">
                            Error in newsletter data
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {newsList.length === 0 && (
                  <p className="text-center">No newsletters found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsLetterEdit;
