import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";

// Load persisted state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("user");
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error("Could not load state", error);
    return undefined;
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state.user);
    localStorage.setItem("user", serializedState);
  } catch (error) {
    console.error("Could not save state", error);
  }
};

// Initialize persisted state
const persistedState = {
  user: loadState(),
};

const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: persistedState,
});

// Subscribe to store updates and save to localStorage
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
