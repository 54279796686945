import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const ContactusEdit = () => {
  const dataTableRef = useRef();
  const { filepath } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [editContact, setEditContact] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    subject: "",
    message: "",
  });
  const [newContact, setNewContact] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    subject: "",
    message: "",
  });
  const navigate = useNavigate();

  const checkPermission = useCheckPermission();

  const canEditContactUs = checkPermission(ADMIN_MODULES.CONTACT_US, PERMISSIONS.EDIT);
  const canDeleteContactUs = checkPermission(ADMIN_MODULES.CONTACT_US, PERMISSIONS.DELETE);
  const canAddContactUs = checkPermission(ADMIN_MODULES.CONTACT_US, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchContacts = async () => {
    try {
      const response = await axiosClient.get("contact");

      if (response.data.success) {
        setContacts(response.data.contacts);
        setLoading(false);

        // Initialize DataTable after data is loaded
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } else {
        throw new Error("Failed to fetch contact messages.");
      }
    } catch (err) {
      setError(
        err.message || "Failed to fetch contact messages. Please try again."
      );
      setLoading(false);
    }
  };

  // Fetch all contact messages on component mount
  useEffect(() => {
    fetchContacts();
  }, []);

  // Handle showing the edit modal with selected contact data
  const handleShowEdit = (contact) => {
    setSelectedContactId(contact?._id);
    setEditContact({
      name: contact?.name || "",
      email: contact?.email || "",
      phone: contact?.phone || "",
      address: contact?.address || "",
      subject: contact?.subject || "",
      message: contact?.message || "",
    });
    setShowEditModal(true);
  };

  // Handle closing the edit modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
    fetchContacts();
  };

  // Handle input changes in edit form
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditContact((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Update contact details
  const updateContact = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axiosClient.put(
        `contact/${selectedContactId}`,
        editContact
      );

      if (response.data.success) {
        // Update the contacts list with the edited contact
        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact._id === selectedContactId
              ? { ...contact, ...response.data.contact }
              : contact
          )
        );
        setShowEditModal(false);
      } else {
        throw new Error("Failed to update contact message.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to update contact message. Please try again."
      );
    }
  };

  // Show the delete confirmation modal
  const handleShowDelete = (contactId) => {
    setSelectedContactId(contactId);
    setShowDeleteModal(true);
    fetchContacts();
  };

  // Handle closing the delete modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Handle contact deletion
  const deleteContact = async () => {
    try {
      const response = await axiosClient.delete(`contact/${selectedContactId}`);

      if (response.data.success) {
        setContacts((prevContacts) =>
          prevContacts.filter((contact) => contact._id !== selectedContactId)
        );
        setShowDeleteModal(false);
      } else {
        throw new Error("Failed to delete contact message.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to delete the contact message. Please try again."
      );
      setShowDeleteModal(false);
    }
  };

  // Show Add Contact Modal
  const handleShowAdd = () => {
    setNewContact({
      name: "",
      email: "",
      phone: "",
      address: "",
      subject: "",
      message: "",
    });
    setError("");
    setShowAddModal(true);
  };

  // Close Add Contact Modal
  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
    fetchContacts();
  };

  // Handle input changes in Add Contact form
  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Submit new contact
  const addContact = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axiosClient.post("contact", newContact);

      if (response.data.success) {
        const createdContact = response.data.contact;
        setContacts([...contacts, createdContact]);
        setShowAddModal(false);
      } else {
        throw new Error("Failed to add new contact message.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to add new contact message. Please try again."
      );
    }
  };

  // Initialize DataTable on contacts update
  useEffect(() => {
    if (dataTableRef.current && !loading && !error) {
      $(dataTableRef.current).DataTable({
        destroy: true, // Allows reinitialization
      });
    }
  }, [contacts, loading, error]);

  if (loading) return <p>Loading contact messages...</p>;

  if (error) return <p className="text-danger">{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Contact Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this contact message?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteContact}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Contact Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Contact Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateContact}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={editContact.name}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={editContact.email}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={editContact.phone}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={editContact.address}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  value={editContact.subject}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  value={editContact.message}
                  onChange={handleEditInputChange}
                  required
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Add Contact Modal */}
        <Modal show={showAddModal} onHide={handleCloseAdd} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Contact Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addContact}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={newContact.name}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={newContact.email}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={newContact.phone}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={newContact.address}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  value={newContact.subject}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  value={newContact.message}
                  onChange={handleAddInputChange}
                  required
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseAdd}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Add Contact
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Contact Messages</h5>
              {canAddContactUs && <div className="add-button" style={{ marginBottom: "20px" }}>
                <button onClick={handleShowAdd} className="btn btn-primary">
                  + Add Contact
                </button>
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Subject</th>
                      <th>Message</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts?.map((contact, index) => (
                      <tr key={contact._id}>
                        <td>{index + 1}</td>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.phone}</td>
                        <td>{contact.address}</td>
                        <td>{contact.subject}</td>
                        <td>{contact.message}</td>
                        <td>
                          {canDeleteContactUs && <i
                            className="fa-solid fa-trash mr-2 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowDelete(contact._id)}
                            title="Delete Contact"
                          ></i>}
                          {canEditContactUs && <i
                            className="fa-solid fa-pen-to-square text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowEdit(contact)}
                            title="Edit Contact"
                          ></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactusEdit;