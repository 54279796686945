import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";

const Payments = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [currentPage, setCurrentPage] = useState(1); // Set current page
  const [pendingPayments, setPendingPayments] = useState([]);
  const [pastPayments, setPastPayments] = useState([]);
  const itemsPerPage = 3; // Items per page
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register"); // Redirect if no token
    } else {
      fetchPaymentsData();
    }
  }, [navigate]);

  const fetchPaymentsData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axiosClient.get(
        `/tests-booking/test/booking/user/${userId}`
      );

      if (response.data.success) {
        const { bookings } = response.data;
        const pending = [];
        const past = [];

        for (const booking of bookings) {
          const testPromises = booking.test_id.map((testId) =>
            axiosClient.get(`/tests/test/${testId}`)
          );

          const testResponses = await Promise.all(testPromises);
          const testNames = testResponses
            .map((res) => res.data.test.title)
            .join(", ");

          const paymentData = {
            id: booking._id,
            testName: testNames,
            orderId: booking.bookingId,
            date: new Date(booking.createdAt).toLocaleDateString(),
            amount: `₹ ${booking.totalAmount}`,
            status: booking.paymentStatus === "Success" ? "Paid" : "Pending",
          };

          if (booking.paymentStatus === "Success") {
            past.push(paymentData);
          } else {
            pending.push(paymentData);
          }
        }

        setPendingPayments(pending);
        setPastPayments(past);
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  };

  const currentPayments =
    activeTab === "pending"
      ? pendingPayments.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      : pastPayments.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  const totalPayments =
    activeTab === "pending" ? pendingPayments.length : pastPayments.length;
  const totalPages = Math.ceil(totalPayments / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          <Sidebar />

          <main className="w-full md:w-3/4 p-4 md:p-8">
            <h1 className="text-xl md:text-2xl font-semibold font-museo mb-6">
              Payments
            </h1>

            <div className="flex border-b-2 my-4 md:my-6">
              <button
                onClick={() => {
                  setActiveTab("pending");
                  setCurrentPage(1);
                }}
                className={`py-2 px-2 md:px-4 text-sm md:text-lg font-semibold ${
                  activeTab === "pending"
                    ? "border-b-2 border-colorb font-museo"
                    : "font7"
                }`}
              >
                Pending Payments
              </button>
              <button
                onClick={() => {
                  setActiveTab("past");
                  setCurrentPage(1);
                }}
                className={`py-2 px-2 md:px-4 text-sm md:text-lg font-semibold ${
                  activeTab === "past"
                    ? "border-b-2 border-colorb font-museo"
                    : "font7"
                }`}
              >
                Past Payments
              </button>
            </div>

            <div className="space-y-4 md:space-y-6">
              {currentPayments.map((payment) => (
                <div
                  key={payment.id}
                  className="p-4 md:p-6 rounded-md shadow-lg"
                >
                  <div className="flex flex-col md:flex-row md:justify-between">
                    <div className="mb-2 md:mb-0">
                      <h2 className="text-lg font-semibold font-museo mb-2">
                        {payment.testName}
                      </h2>
                      <p className="text-xs md:text-sm font7 font-semibold font-mont mb-1 md:mb-2">
                        Order Id:{" "}
                        <span className="font-semibold bg-[#DF413299] px-2 py-1 ml-2 rounded-md text-white text-xs font-museo">
                          {payment.orderId}
                        </span>
                      </p>
                      <p className="text-xs md:text-sm font7 font-semibold font-mont">
                        Date of Order: {payment.date}
                      </p>
                    </div>
                    <div className="text-left md:text-right mt-2 md:mt-0">
                      <h2 className="text-lg font-semibold mb-1 md:mb-2 font-museo">
                        {payment.amount}
                      </h2>
                      <p className="text-xs md:text-sm font7 font-semibold font-mont">
                        Status:{" "}
                        <span
                          className={`text-xs font-semibold ${
                            payment.status === "Pending"
                              ? "bg-[#DF413299]"
                              : "bg-[#1E4FA699]"
                          } px-2 py-1 ml-2 rounded-md text-white font-museo`}
                        >
                          {payment.status}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex justify-between mt-4">
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-3 py-2 rounded-md font-museo text-sm md:text-base disabled:opacity-50"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-4 md:px-6 py-2 rounded-md font-museo text-sm md:text-base disabled:opacity-50"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Payments;
