import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { Link, useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const BookingEdit = () => {
  const dataTableRef = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [editBooking, setEditBooking] = useState({
    serviceType: "",
    paymentMethod: "",
    totalAmount: 0,
    bookingId: "",
    paymentStatus: "",
    bookingStatus: "",
    testDate: "",
    timeSlot: "",
    isHome: false,
    homeAddress: "",
    labAddress: "",
    physicalCopy: false,
  });
  const checkPermission = useCheckPermission();

  const canEditBooking = checkPermission(ADMIN_MODULES.TEST_BOOKING, PERMISSIONS.EDIT);
  const canDeleteBooking = checkPermission(ADMIN_MODULES.TEST_BOOKING, PERMISSIONS.DELETE);


  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axiosClient.get("/tests-booking/test/booking");
        setBookings(response.data.booking);
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        setError("Failed to fetch bookings. Please try again.");
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const handleShowEdit = (booking) => {
    setSelectedBookingId(booking?._id);
    setEditBooking({
      serviceType: booking?.serviceType || "",
      paymentMethod: booking?.paymentMethod || "",
      totalAmount: booking?.totalAmount || 0,
      bookingId: booking?.bookingId || "",
      paymentStatus: booking?.paymentStatus || "",
      bookingStatus: booking?.bookingStatus || "",
      testDate: booking?.testDate
        ? new Date(booking?.testDate).toISOString().split("T")[0]
        : "",
      timeSlot: booking?.timeSlot || "",
      isHome: booking?.isHome || false,
      homeAddress: booking?.homeAddress || "",
      labAddress: booking?.labAddress || "",
      physicalCopy: booking?.physicalCopy || false,
    });
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setError("");
  };

  const updateBooking = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.put(
        `/tests-booking/test/booking/${selectedBookingId}`,
        editBooking
      );

      // Update the bookings list with the edited booking
      setBookings(
        bookings.map((booking) =>
          booking?._id === selectedBookingId
            ? { ...booking, ...editBooking }
            : booking
        )
      );
      setShowEdit(false);
    } catch (err) {
      setError("Failed to update booking. Please try again.");
    }
  };

  const handleShow = (bookingId) => {
    setSelectedBookingId(bookingId);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setEditBooking((prev) => ({
      ...prev,
      [name]: inputValue,
    }));
  };

  const handleClose = () => setShow(false);

  const deleteBooking = async () => {
    try {
      await axiosClient.delete(
        `/tests-booking/test/booking/${selectedBookingId}`
      );

      setBookings(
        bookings.filter((booking) => booking?._id !== selectedBookingId)
      );
      setShow(false);
    } catch (err) {
      setError("Failed to delete the booking. Please try again.");
      setShow(false);
    }
  };

  if (loading) return <p>Loading bookings...</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this booking?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteBooking}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Booking Modal */}
        <Modal show={showEdit} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateBooking}>
              {/* <div className="mb-3">
                <label className="form-label">Service Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="serviceType"
                  value={editBooking?.serviceType}
                  onChange={handleInputChange}
                  required
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label">Payment Method</label>
                <input
                  type="text"
                  className="form-control"
                  name="paymentMethod"
                  value={editBooking?.paymentMethod}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Total Amount</label>
                <input
                  type="number"
                  className="form-control"
                  name="totalAmount"
                  value={editBooking?.totalAmount}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="bookingId"
                  value={editBooking?.bookingId}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Payment Status</label>
                <input
                  type="text"
                  className="form-control"
                  name="paymentStatus"
                  value={editBooking?.paymentStatus}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Status</label>
                <input
                  type="text"
                  className="form-control"
                  name="bookingStatus"
                  value={editBooking?.bookingStatus}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Test Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="testDate"
                  value={editBooking?.testDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Time Slot</label>
                <input
                  type="text"
                  className="form-control"
                  name="timeSlot"
                  value={editBooking?.timeSlot}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Is Home Test</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="isHome"
                  checked={editBooking?.isHome}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Home Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="homeAddress"
                  value={editBooking?.homeAddress}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Lab Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="labAddress"
                  value={editBooking?.labAddress}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Physical Copy Required</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="physicalCopy"
                  checked={editBooking?.physicalCopy}
                  onChange={handleInputChange}
                />
              </div>
              {error && <p className="text-danger">{error}</p>}

              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Booking Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table
                  id="datatable"
                  className="table table-hover"
                  ref={dataTableRef}
                >
                  <thead>
                    <tr>
                      <th>B.Id</th>
                      {/* <th>Service Type</th> */}
                      <th>Payment Method</th>
                      <th>Total Amount</th>
                      <th>Payment Status</th>
                      <th>Booking Status</th>
                      <th>Booking Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td><Link to={`/bookings/${booking?._id}`}>{booking?.bookingId}</Link></td>
                        <td>{booking?.paymentMethod}</td>
                        <td>{booking?.totalAmount}</td>
                        <td>{booking?.paymentStatus}</td>
                        <td>{booking?.bookingStatus}</td>
                        <td>
                          {new Date(booking?.testDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>
                          {canEditBooking &&<i
                            onClick={() => handleShowEdit(booking)}
                            className="fa-solid fa-pen-to-square mr-2"
                            title="Edit Booking"
                            style={{ cursor: "pointer" }}
                          ></i>}
                          {canDeleteBooking && <i
                            className="fa-solid fa-trash mr-2"
                            onClick={() => handleShow(booking?._id)}
                            style={{ cursor: "pointer" }}
                            title="Delete Booking"
                          ></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingEdit;
