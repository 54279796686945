import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const GiftCardEdit = () => {
  const dataTableRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editErrors, setEditErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});
  const [selectedGiftCardId, setSelectedGiftCardId] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [editGiftCard, setEditGiftCard] = useState({
    cardName: "",
    couponCode: "",
    percentageOff: 0,
    isApplyOnce: false,
  });
  const [newGiftCard, setNewGiftCard] = useState({
    cardName: "",
    couponCode: "",
    percentageOff: 0,
    isApplyOnce: false,
  });
  const navigate = useNavigate();

  const checkPermission = useCheckPermission();

  const canEditGiftCard = checkPermission(ADMIN_MODULES.GIFT_CARD, PERMISSIONS.EDIT);
  const canDeleteGiftCard = checkPermission(ADMIN_MODULES.GIFT_CARD, PERMISSIONS.DELETE);
  const canAddGiftCard = checkPermission(ADMIN_MODULES.GIFT_CARD, PERMISSIONS.ADD)


  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [navigate]);

  // Initialize DataTable
  useEffect(() => {
    if (!dataTable && !loading && giftCards.length > 0 && dataTableRef.current) {
      const newDataTable = $(dataTableRef.current).DataTable({
        data: giftCards,
        columns: [
          { 
            title: 'S.No.',
            render: (data, type, row, meta) => meta.row + 1
          },
          { 
            title: 'Card Name',
            data: 'cardName'
          },
          { 
            title: 'Coupon Code',
            data: 'couponCode'
          },
          { 
            title: 'Percentage Off',
            data: 'percentageOff',
            render: (data) => `${data}%`
          },
          {
            title: 'Actions',
            data: null,
            orderable: false,
            render: (data, type, row) => {
              return `
                <i class="fa-solid fa-trash mr-2" data-id="${row._id}" style="cursor: pointer; margin-right: 10px;"></i>
                <i class="fa-solid fa-pen-to-square" data-id="${row._id}" style="cursor: pointer;"></i>
              `;
            }
          }
        ],
        responsive: true,
        ordering: true,
        searching: true,
        lengthChange: true,
        pageLength: 10,
        destroy: true
      });

      // Handle delete click
      $(dataTableRef.current).on('click', '.fa-trash', function() {
        const id = $(this).data('id');
        handleShowDelete(id);
      });

      // Handle edit click
      $(dataTableRef.current).on('click', '.fa-pen-to-square', function() {
        const id = $(this).data('id');
        const giftCard = giftCards.find(card => card._id === id);
        handleShowEdit(giftCard);
      });

      setDataTable(newDataTable);
    }
    
    return () => {
      if (dataTable) {
        dataTable.destroy();
        setDataTable(null);
      }
    };
  }, [loading, giftCards]);

  // Fetch all gift cards on component mount
  useEffect(() => {
    const fetchGiftCards = async () => {
      try {
        const response = await axiosClient.get("gift");
        setGiftCards(response.data.giftCards);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch gift cards. Please try again.");
        setLoading(false);
      }
    };

    fetchGiftCards();
  }, []);

  // Show Edit Modal with selected gift card data
  const handleShowEdit = (giftCard) => {
    setSelectedGiftCardId(giftCard?._id);
    setEditGiftCard({
      cardName: giftCard?.cardName || "",
      couponCode: giftCard?.couponCode || "",
      percentageOff: giftCard?.percentageOff || "",
      isApplyOnce: giftCard?.isApplyOnce || false,
    });
    setShowEditModal(true);
  };

  // Update DataTable when giftCards change
  useEffect(() => {
    if (dataTable) {
      dataTable.clear();
      dataTable.rows.add(giftCards);
      dataTable.draw();
    }
  }, [giftCards]);


  // Close Edit Modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
  };

  // Update gift card details
  const updateGiftCard = async (e) => {
    e.preventDefault();
    if (!validateEditForm()) return;

    try {
      await axiosClient.put(`gift/${selectedGiftCardId}`, editGiftCard);

      // Update the gift cards list with the edited gift card
      setGiftCards(
        giftCards.map((giftCard) =>
          giftCard._id === selectedGiftCardId ? { ...giftCard, ...editGiftCard } : giftCard
        )
      );

      setShowEditModal(false);
      setEditErrors({});
    } catch (err) {
      setError("Failed to update gift card. Please try again.");
    }
  };

  // Show Delete Confirmation Modal
  const handleShowDelete = (giftCardId) => {
    setSelectedGiftCardId(giftCardId);
    setShowDeleteModal(true);
  };

  // Close Delete Modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Delete gift card
  const deleteGiftCard = async () => {
    try {
      await axiosClient.delete(`gift/${selectedGiftCardId}`);
      setGiftCards(giftCards.filter((giftCard) => giftCard._id !== selectedGiftCardId));
      setShowDeleteModal(false);
    } catch (err) {
      setError("Failed to delete the gift card. Please try again.");
      setShowDeleteModal(false);
    }
  };

  // Show Add Gift Card Modal
  const handleShowAdd = () => {
    setNewGiftCard({
      cardName: "",
      couponCode: "",
      percentageOff: 0,
      isApplyOnce: false,
    });
    setError("");
    setShowAddModal(true);
  };

  // Close Add Gift Card Modal
  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
  };

  // Handle input changes in Add Gift Card form with validation
  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setNewGiftCard((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Submit new gift card
  const addGiftCard = async (e) => {
    e.preventDefault();
    if (!validateAddForm()) return;

    try {
      const response = await axiosClient.post("gift", newGiftCard);
      const createdGiftCard = response.data.giftCard;
      setGiftCards([...giftCards, createdGiftCard]);
      setShowAddModal(false);
      setAddErrors({});
    } catch (err) {
      setError("Failed to add new gift card. Please try again.");
    }
  };

  // Validation for Edit Form
  const validateEditForm = () => {
    const errors = {};
    if (!editGiftCard.cardName.trim()) {
      errors.cardName = "Card Name is required.";
    }
    if (!editGiftCard.couponCode.trim()) {
      errors.couponCode = "Coupon Code is required.";
    }
    if (!editGiftCard.percentageOff || isNaN(editGiftCard.percentageOff)) {
      errors.percentageOff = "Percentage Off must be a number.";
    }

    setEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validation for Add Form
  const validateAddForm = () => {
    const errors = {};
    if (!newGiftCard.cardName.trim()) {
      errors.cardName = "Card Name is required.";
    }
    if (!newGiftCard.couponCode.trim()) {
      errors.couponCode = "Coupon Code is required.";
    }
    if (!newGiftCard.percentageOff || isNaN(newGiftCard.percentageOff)) {
      errors.percentageOff = "Percentage Off must be a number.";
    }

    setAddErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Gift Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this Gift Card?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteGiftCard}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Gift Card Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Gift Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateGiftCard}>
              {error && <div className="alert alert-danger">{error}</div>}

              {/* Card Name Field */}
            <div className="mb-3">
              <label className="form-label">Card Name</label>
              <input
                type="text"
                className={`form-control ${editErrors.cardName ? "is-invalid" : ""}`}
                name="cardName"
                value={editGiftCard.cardName}
                onChange={(e) => setEditGiftCard({...editGiftCard, cardName: e.target.value})}
                required
              />
              {editErrors.cardName && (
                <div className="invalid-feedback">{editErrors.cardName}</div>
              )}
            </div>

            {/* Coupon Code Field */}
            <div className="mb-3">
              <label className="form-label">Coupon Code</label>
              <input
                type="text"
                className={`form-control ${editErrors.couponCode ? "is-invalid" : ""}`}
                name="couponCode"
                value={editGiftCard.couponCode}
                onChange={(e) => setEditGiftCard({...editGiftCard, couponCode: e.target.value})}
                required
              />
              {editErrors.couponCode && (
                <div className="invalid-feedback">{editErrors.couponCode}</div>
              )}
            </div>

            {/* Percentage Off Field */}
            <div className="mb-3">
              <label className="form-label">Percentage Off</label>
              <input
                type="number"
                className={`form-control ${editErrors.percentageOff ? "is-invalid" : ""}`}
                name="percentageOff"
                value={editGiftCard.percentageOff}
                onChange={(e) => setEditGiftCard({...editGiftCard, percentageOff: e.target.value})}
                required
              />
              {editErrors.percentageOff && (
                <div className="invalid-feedback">{editErrors.percentageOff}</div>
              )}
            </div>

            {/* checkbox feild for apply coupon once or not */}
            <div className="mb-3">
              <input
                type="checkbox"
                className={`form-check-input`}
                name="isApplyOnce"
                checked={editGiftCard.isApplyOnce}
                onChange={(e) => setEditGiftCard({...editGiftCard, isApplyOnce: e.target.checked})}
              />
              <label className="form-label">Is Apply Only Once?</label>
            </div>

              {/* Modal Footer Buttons */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

         {/* Add Gift Card Modal */}
      <Modal show={showAddModal} onHide={handleCloseAdd} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Gift Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addGiftCard}>
            {error && <div className="alert alert-danger">{error}</div>}

            {/* Card Name Field */}
            <div className="mb-3">
              <label className="form-label">Card Name</label>
              <input
                type="text"
                className={`form-control ${addErrors.cardName ? "is-invalid" : ""}`}
                name="cardName"
                value={newGiftCard.cardName}
                onChange={handleAddInputChange}
                required
              />
              {addErrors.cardName && (
                <div className="invalid-feedback">{addErrors.cardName}</div>
              )}
            </div>

            {/* Coupon Code Field */}
            <div className="mb-3">
              <label className="form-label">Coupon Code</label>
              <input
                type="text"
                className={`form-control ${addErrors.couponCode ? "is-invalid" : ""}`}
                name="couponCode"
                value={newGiftCard.couponCode}
                onChange={handleAddInputChange}
                required
              />
              {addErrors.couponCode && (
                <div className="invalid-feedback">{addErrors.couponCode}</div>
              )}
            </div>

            {/* Percentage Off Field */}
            <div className="mb-3">
              <label className="form-label">Percentage Off</label>
              <input
                type="number"
                className={`form-control ${addErrors.percentageOff ? "is-invalid" : ""}`}
                name="percentageOff"
                value={newGiftCard.percentageOff}
                onChange={handleAddInputChange}
                required
              />
              {addErrors.percentageOff && (
                <div className="invalid-feedback">{addErrors.percentageOff}</div>
              )}
            </div>

            {/* checkbox feild for apply coupon once or not */}
            <div className="mb-3">
              <input
                type="checkbox"
                className={`form-check-input`}
                name="isApplyOnce"
                checked={newGiftCard.isApplyOnce}
                onChange={(e) => setNewGiftCard({...newGiftCard, isApplyOnce: e.target.checked})}
              />
              <label className="form-label">Is Apply Only Once?</label>
            </div>

            {/* Modal Footer Buttons */}
            <div className="modal-footer">
              <button
                type="button"
                className="modal-footer-btn"
                onClick={handleCloseAdd}
              >
                Cancel
              </button>
              <button type="submit" className="modal-footer-btn">
                Add Gift Card
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="container">
        <div className="row foot-tablerow">
          <div className="heading-and-button">
            <h5>Gift Cards</h5>
            {canAddGiftCard && <div className="add-button">
              <button onClick={handleShowAdd}>+ Add Gift Card</button>
            </div>}
          </div>
          <div className="col-lg-12 maintable-column">
            <div className="container mt-4 overflow-auto">
              <table id="datatable" className="table" ref={dataTableRef}>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Card Name</th>
                    <th>Coupon Code</th>
                    <th>Percentage Off</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {giftCards?.map((giftCard, index) => (
                    <tr key={giftCard._id}>
                      <td>{index + 1}</td>
                      <td>{giftCard.cardName}</td>
                      <td>{giftCard.couponCode}</td>
                      <td>{giftCard.percentageOff}%</td>
                      <td>
                        {canDeleteGiftCard && <i
                          className="fa-solid fa-trash mr-2"
                          onClick={() => handleShowDelete(giftCard._id)}
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        ></i>}
                        {canEditGiftCard && <i
                          onClick={() => handleShowEdit(giftCard)}
                          className="fa-solid fa-pen-to-square"
                          style={{ cursor: "pointer" }}
                        ></i>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);}

export default GiftCardEdit;
