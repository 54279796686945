// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Hero from "./components/landingPage/Hero";
// import Register from "./components/login/Register";
// import Blog from "./components/blog/Blog";
// import OtpVerification from "./components/login/otpVerification";
// import BodyCheckup from "./components/bodyCheckup/hero";
// import MultiStepForm from "./components/cartPages/cart";
// import Profile from "./components/profile/Profile";
// import FamilyMembers from "./components/profile/FamilyMembers";
// import SavedAddresses from "./components/profile/Address";
// import MedicalSummary from "./components/profile/MedicalSummary";
// import Login from "./components/admin/login/Login";
// import Main from "./components/admin/Main";
// // import PrivateRoute from "./components/Route/PrivateRoute";
// // import AddTestCategory from "./components/admin/AddTestCategory";
// // import EditTestCategory from "./components/admin/EditTestCategory";
// // import TestCategory from "./components/admin/TestCategory";
// // import AddTest from "./components/admin/AddTest";
// // import EditTest from "./components/admin/EditTest";
// // import Test from "./components/admin/Test";
// // import AddTestParameter from "./components/admin/AddTestParameter";
// // import EditTestParameter from "./components/admin/EditTestParameter";
// // import TestParameter from "./components/admin/TestParameter";
// // import AdminProfile from "./components/admin/AdminProfile";
// // import UserDetailsEdit from "./components/admin/UserDetailsEdit";
// import UsersEdit from "./components/admin/UsersEdit";
// import Tests from "./components/Tests";
// import About from "./components/about/About";
// import Prescriptions from "./components/profile/Prescriptions";
// import Payments from "./components/profile/Payments";
// import BookingReports from "./components/profile/Reports";

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <Routes>
//           <Route exact path="/" element={<Hero />} />
//           {/* <Route exact path="/login" element={<Login />} /> */}
//           <Route exact path="/register" element={<Register />} />
//           <Route exact path="/otp" element={<OtpVerification />} />
//           <Route exact path="/testDetail" element={<BodyCheckup />} />
//           <Route exact path="/testBrowse" element={<Tests />} />
//           <Route exact path="/about" element={<About />} />
//           <Route exact path="/profile" element={<Profile />} />
//           <Route exact path="/familyMembers" element={<FamilyMembers />} />
//           <Route exact path="/addresses" element={<SavedAddresses />} />
//           <Route exact path="/medical-summary" element={<MedicalSummary />} />
//           <Route exact path="/prescription" element={<Prescriptions />} />
//           <Route exact path="/bookings-reports" element={<BookingReports />} />
//           <Route exact path="/payments" element={<Payments />} />
//           <Route exact path="/blog" element={<Blog />} />
//           <Route exact path="/steps" element={<MultiStepForm />} />
//           <Route exact path="/admin/login" element={<Login />} />
//           <Route exact path="/adminDashboard" element={<Main />} />
//           <Route exact path="/admin/usersedit" element={<UsersEdit />} />
//           {/* <Route
//             exact
//             path="/admin/dashboard"
//             element={<PrivateRoute element={Main} isAdminRoute />}
//           />``

//           <Route
//             exact
//             path="/admin/add-test-category"
//             element={<PrivateRoute element={AddTestCategory} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/edit-test-category/:id"
//             element={<PrivateRoute element={EditTestCategory} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/test-category-details"
//             element={<PrivateRoute element={TestCategory} isAdminRoute />}
//           />

//           <Route
//             exact
//             path="/admin/add-test"
//             element={<PrivateRoute element={AddTest} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/edit-test/:id"
//             element={<PrivateRoute element={EditTest} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/all-test"
//             element={<PrivateRoute element={Test} />}
//             isAdminRoute
//           />
//           <Route
//             exact
//             path="/admin/add-test-parameter"
//             element={<PrivateRoute element={AddTestParameter} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/edit-test-parameter/:id"
//             element={<PrivateRoute element={EditTestParameter} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/all-test-parameter"
//             element={<PrivateRoute element={TestParameter} isAdminRoute />}
//           />

//           <Route
//             exact
//             path="/admin/profile"
//             element={<PrivateRoute element={AdminProfile} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/usersedit"
//             element={<PrivateRoute element={UsersEdit} isAdminRoute />}
//           />
//           <Route
//             exact
//             path="/admin/user-details"
//             element={<PrivateRoute element={UserDetailsEdit} isAdminRoute />}
//           /> */}
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./components/landingPage/Hero";
import Register from "./components/login/Register";
import Blog from "./components/blog/Blog";
import OtpVerification from "./components/login/otpVerification";
import BodyCheckup from "./components/bodyCheckup/hero";
import MultiStepForm from "./components/cartPages/cart";
import Profile from "./components/profile/Profile";
import FamilyMembers from "./components/profile/FamilyMembers";
import SavedAddresses from "./components/profile/Address";
import MedicalSummary from "./components/profile/MedicalSummary";
import Login from "./components/admin/login/Login";
import Main from "./components/admin/Main";
import UsersEdit from "./components/admin/UsersEdit";
import Tests from "./components/Tests";
import About from "./components/about/About";
import Prescriptions from "./components/profile/Prescriptions";
import TestCategory from "./components/admin/TestCategory";
import BookingReports from "./components/profile/Reports";
import Payments from "./components/profile/Payments";
import Contact from "./components/contact/Contact";
import PrivacyPolicy from "./components/policy/PrivacyPolicy";
import Terms from "./components/policy/Terms";
import RefundPolicy from "./components/policy/RefundPolicy";
import AddTestCategory from "./components/admin/AddTestCategory";
import EditTestCategory from "./components/admin/EditTestCategory";
import AddTest from "./components/admin/AddTest";
import EditTest from "./components/admin/EditTest";
import Test from "./components/admin/Test";
import AddTestParameter from "./components/admin/AddTestParameter";
import EditTestParameter from "./components/admin/EditTestParameter";
import TestParameter from "./components/admin/TestParameter";
import FAQ from "./components/policy/FAQ";
import Congratulation from "./components/cartPages/Congratulation";
import LabsEdit from "./components/admin/LabsEdit";
import ScrollToTop from "./ScrollToTop";
import DoctorsEdit from "./components/admin/DoctorsEdit";
import UserDetails from "./components/admin/UserDetails";
import CustomerReviewEdit from "./components/admin/CustomerReviewEdit";
import NewsLetterEdit from "./components/admin/NewsLetterEdit";
import BookingEdit from "./components/admin/BookingEdit";
import BookingDetails from "./components/admin/BookingDetails";
import DetailsEdit from "./components/admin/DetailsEdit";
import TermsEditor from "./components/admin/TermsEditor";
import RefundPolicyEditor from "./components/admin/RefundPolicyEditor";
import PrivacyPolicyEditor from "./components/admin/PrivacyPolicyEditor";
import axiosClient from "./axios-client";
import { useEffect } from "react";
import PaymentFaild from "./components/cartPages/PaymetFaild";
import GiftCardEdit from "./components/admin/GiftCardEdit";
import PaymentStatus from "./components/cartPages/PaymentStatus";
import ContactusEdit from "./components/admin/ContactusEdit";

function App() {
  useEffect(() => {
    const checkTokenValidity = async () => {
      //   const token = localStorage.getItem("token");
      //   const userId = localStorage.getItem("userId");

      //   if (token && userId) {
      //     try {
      //       // Try to validate the token by calling the backend route
      //       await axiosClient.get("/users/validate-token", {
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //       });
      //     } catch (error) {
      //       // If token validation fails, clear localStorage and redirect
      //       console.error("Invalid token, logging out...");
      //       localStorage.removeItem("token");
      //       localStorage.removeItem("userId");
      //       localStorage.removeItem("admintoken");
      //       localStorage.removeItem("adminname");
      //       localStorage.removeItem("adminemail");
      //       localStorage.removeItem("adminId");
      //     }
      //   }
      // };
      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        if (token && userId) {
          await axiosClient.get("/users/validate-token", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      } catch (error) {
        console.error("Invalid token, logging out...");
        // localStorage.clear();
      }
    };
    checkTokenValidity();
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Hero />} />
          {/* <Route exact path="/login" element={<Login />} /> */}
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/otp" element={<OtpVerification />} />
          <Route exact path="/testDetail/:id" element={<BodyCheckup />} />
          <Route exact path="/testBrowse" element={<Tests />} />
          <Route exact path="/tests/category/:categoryId" element={<Tests />} />
          <Route exact path="/tests/lab/:labId" element={<Tests />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/familyMembers" element={<FamilyMembers />} />
          <Route exact path="/addresses" element={<SavedAddresses />} />
          <Route exact path="/medical-summary" element={<MedicalSummary />} />
          <Route exact path="/prescription" element={<Prescriptions />} />
          <Route exact path="/bookings-reports" element={<BookingReports />} />
          <Route exact path="/payments" element={<Payments />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/steps" element={<MultiStepForm />} />
          <Route exact path="/congrats" element={<Congratulation />} />
          <Route exact path="/admin/login" element={<Login />} />
          <Route exact path="/admin/dashboard" element={<Main />} />
          <Route exact path="/admin/usersedit" element={<UsersEdit />} />
          <Route exact path="/users/:id" element={<UserDetails />} />
          <Route exact path="/bookings/:id" element={<BookingDetails />} />
          <Route exact path="/admin/labsedit" element={<LabsEdit />} />
          <Route exact path="/admin/giftcardedit" element={<GiftCardEdit />} />
          <Route exact path="/admin/termsedit" element={<TermsEditor />} />
          <Route exact path="/payment-status" element={<PaymentStatus />} />
          <Route
            exact
            path="/admin/refundedit"
            element={<RefundPolicyEditor />}
          />
          <Route
            exact
            path="/admin/privacyedit"
            element={<PrivacyPolicyEditor />}
          />
          <Route
            exact
            path="/admin/general-details"
            element={<DetailsEdit />}
          />
          <Route exact path="/admin/doctoredit" element={<DoctorsEdit />} />
          <Route exact path="/admin/bookingedit" element={<BookingEdit />} />
          <Route
            exact
            path="/admin/newsletteredit"
            element={<NewsLetterEdit />}
          />
          <Route
            exact
            path="/admin/customerreviewedit"
            element={<CustomerReviewEdit />}
          />
          <Route exact path="/admin/contactus" element={<ContactusEdit />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/refund" element={<RefundPolicy />} />
          <Route
            exact
            path="/admin/test-category-details"
            element={<TestCategory />}
          />
          <Route
            exact
            path="/admin/add-test-category"
            element={<AddTestCategory />}
          />
          <Route
            exact
            path="/admin/edit-test-category/:id"
            element={<EditTestCategory />}
          />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/admin/add-test" element={<AddTest />} />
          <Route exact path="/admin/edit-test/:id" element={<EditTest />} />
          <Route exact path="/admin/all-test" element={<Test />} />
          <Route
            exact
            path="/admin/all-test-parameter"
            element={<TestParameter />}
          />
          <Route
            exact
            path="/admin/add-test-parameter"
            element={<AddTestParameter />}
          />
          <Route
            exact
            path="/admin/edit-test-parameter/:id"
            element={<EditTestParameter />}
          />
          <Route exact path="/Faild" element={<PaymentFaild />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
