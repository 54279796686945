import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const TestParameter = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [tests, setTests] = useState([]);
  const [testParameters, setTestParameters] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedParameterId, setSelectedParameterId] = useState(null);
  const navigate = useNavigate();

  const checkPermission = useCheckPermission();

  const canEditTest = checkPermission(ADMIN_MODULES.TEST, PERMISSIONS.EDIT);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  // Fetch Tests and Parameters
  useEffect(() => {
    const fetchTestsAndParameters = async () => {
      try {
        // Fetching all tests
        const testsResponse = await axiosClient.get("/tests/tests");
        const testsData = testsResponse.data.test;
        setTests(testsData);

        // Fetching all test parameters and storing them by testId
        const paramsResponse = await axiosClient.get(
          "tests-parameters/test/parameters"
        );
        const paramsData = paramsResponse.data;
        const paramsByTestId = paramsData.reduce((acc, param) => {
          acc[param.testId] = param.testParameters;
          return acc;
        }, {});

        setTestParameters(paramsByTestId);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tests or test parameters:", error);
        setError("Failed to fetch tests or test parameters. Please try again.");
        setLoading(false);
      }
    };

    fetchTestsAndParameters();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [tests, testParameters]); // Trigger DataTable when tests change

  const handleShow = (id) => {
    setSelectedParameterId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedParameterId(null);
  };

  const handleDelete = async () => {
    if (selectedParameterId) {
      try {
        await axiosClient.delete(
          `tests-parameters/test/parameter/${selectedParameterId}`
        );
        setTestParameters((prev) =>
          prev.filter((param) => param._id !== selectedParameterId)
        );
        setSuccess("Test Parameters deleted successfully!");
        setError("");
        handleClose();
      } catch (error) {
        console.error("Error deleting test parameter:", error);
        setError("Failed to delete the test parameter. Please try again.");
        setSuccess("");
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Test</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this test?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Test Parameters</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {/* <div className="add-button">
                <Link to="/admin/add-test-parameter">
                  <button>+ Add Test Parameter</button>
                </Link>
              </div> */}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Test Title</th>
                      {/* <th>Parameters</th> */}
                      {canEditTest && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {tests.length > 0 ? (
                      tests.map((test, index) => (
                        <tr key={test._id}>
                          <td>{index + 1}</td>
                          <td>{test.title}</td>
                          {/* <td>
                            <select
                              className="form-select"
                              aria-label="Select Parameter Title"
                            >
                              <option value="" disabled selected>
                                Select Parameter Title
                              </option>
                              {testParameters[test._id] &&
                              testParameters[test._id].length > 0 ? (
                                testParameters[test._id].map((param) => (
                                  <option key={param._id} value={param.title}>
                                    {param.title}
                                  </option>
                                ))
                              ) : (
                                <option disabled>No Titles Available</option>
                              )}
                            </select>
                          </td> */}
                          {canEditTest &&<td>
                            <Link to={`/admin/edit-test-parameter/${test._id}`}>
                              <i className="fa-regular fa-pen-to-square"></i>
                            </Link>
                            {/* &nbsp; */}
                            {/* <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(test._id)}
                            ></i> */}
                          </td>}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No Tests available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestParameter;
