import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const apipath = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;
  const filepath = `${process.env.REACT_APP_API_BASE_URL}/media/`;

  const login = () => {
    setIsLoggedIn(true);
  };

  const adminLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  const addToCart = (testId) => {
    setCart((prevCart) => {
      if (!prevCart.includes(testId)) {
        const updatedCart = [...prevCart, testId];
        // Save the updated cart to localStorage
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      }
      return prevCart;
    });
  };

  const removeFromCart = (testId) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter((id) => id !== testId);
      // Save the updated cart to localStorage
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const isInCart = (testId) => {
    return cart.includes(testId);
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      // const expTime = decodedToken.exp;
      // console.log("data", expTime);
      // const remainingTime = expTime - currentTime;
      // console.log("data exp", remainingTime);
      // const minutes = remainingTime / 60;
      // console.log(`${minutes.toFixed(2)} minutes`);

      if (decodedToken.exp < currentTime) {
        console.log("Token is expired");
        localStorage.clear();

        adminLogout();
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        apipath,
        login,
        adminLogout,
        filepath,
        cart,
        addToCart,
        removeFromCart,
        isInCart,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
