import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Container, Form } from "react-bootstrap";

const SetAdminUserPermissionUser = ({ permissions, setPermissions ,show , setShow,resetPermissions}) => {


  const moduleDisplayNames = {
    users: "Users Management",
    labs: "Labs Management",
    doctor: "Doctor Details",
    customerReview: "Customer Reviews",
    newsLetter: "Newsletter Management",
    generalDetails: "General Details",
    giftCard: "Gift Card Management",
    contactUs: "Contact Us Messages",
    testBooking: "Test Booking",
    test: "Test Details",
    policy: "Policy Management",
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCheckboxChange = (moduleName, permission) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions[moduleName] };

      if (permission === "view") {
        updatedPermissions.view = !updatedPermissions.view;
      } else {
        updatedPermissions[permission] = !updatedPermissions[permission];
        if (updatedPermissions[permission]) {
          updatedPermissions.view = true; // Automatically check "view"
        } else {
          // Check if all other permissions are false, then allow unchecking "view"
          if (
            !updatedPermissions.add &&
            !updatedPermissions.edit &&
            !updatedPermissions.delete
          ) {
            updatedPermissions.view = false;
          }
        }
      }

      return {
        ...prevPermissions,
        [moduleName]: updatedPermissions,
      };
    });
  };

  useEffect(() => {
    console.log(permissions, "<<<permissions");
  }, [permissions]);

  return (
    <Container className="w-fit mx-0">
      <Button variant="primary" size="md" block onClick={handleShow}>
        Set Permssion
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign Permissions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Module</th>
                <th>View</th>
                <th>Add</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(permissions).map((moduleName) => (
                <tr key={moduleName}>
                  <td>{moduleDisplayNames[moduleName]}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={permissions[moduleName].view}
                      onChange={() => handleCheckboxChange(moduleName, "view")}
                      disabled={
                        permissions[moduleName].add ||
                        permissions[moduleName].edit ||
                        permissions[moduleName].delete
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={permissions[moduleName].add}
                      onChange={() => handleCheckboxChange(moduleName, "add")}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={permissions[moduleName].edit}
                      onChange={() => handleCheckboxChange(moduleName, "edit")}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={permissions[moduleName].delete}
                      onChange={() =>
                        handleCheckboxChange(moduleName, "delete")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={() => setShow(false)}>
            Save
          </Button>
          <Button variant="danger" onClick={resetPermissions}>
            Clear Permissions
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SetAdminUserPermissionUser;
