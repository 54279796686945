import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const DetailsEdit = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editErrors, setEditErrors] = useState({});
  const [details, setDetails] = useState(null);
  const [editDetails, setEditDetails] = useState({
    phoneNumber: "",
    supportEmail: "",
    minPrice: 0,
    maxPrice: 0,
  });
  
  const navigate = useNavigate();
  const checkPermission = useCheckPermission();

  const canEditGeneralDetails = checkPermission(ADMIN_MODULES.GENERAL_DETAILS, PERMISSIONS.EDIT);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [navigate]);

  // Fetch details function - separated for reuse
  const fetchDetails = async () => {
    try {
      const response = await axiosClient.get("details");
      setDetails(response.data.generalDetails);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch details. Please try again.");
      setLoading(false);
    }
  };

  // Fetch details on component mount
  useEffect(() => {
    fetchDetails();
  }, []);

  // Handle showing the edit modal with current details
  const handleShowEdit = () => {
    setEditDetails({
      phoneNumber: details?.phoneNumber || "",
      supportEmail: details?.supportEmail || "",
      minPrice: details?.minPrice || 0,
      maxPrice: details?.maxPrice || 0,
    });
    setShowEditModal(true);
  };

  // Close the edit modal and reset form
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
    setEditErrors({});
    setEditDetails({
      phoneNumber: "",
      supportEmail: "",
      minPrice: 0,
      maxPrice: 0,
    });
  };

  // Handle input changes in edit form with validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate the specific field
    setEditErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (!value.trim()) {
        newErrors[name] = `${formatFieldName(name)} is required.`;
      } else if (name === "phoneNumber" && !/^\d{10,11}$/.test(value)) {
        newErrors[name] = "Phone number must be 10-11 digits.";
      } else if (name === "supportEmail" && !isValidEmail(value)) {
        newErrors[name] = "Please enter a valid email address.";
      } else if (["minPrice", "maxPrice"].includes(name) && (isNaN(value) || value < 0)) {
        newErrors[name] = `${formatFieldName(name)} must be a positive number.`;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  // Helper function to format field names
  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };

  // Helper function to validate email
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Update details
  const updateDetails = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await axiosClient.post("details", editDetails);
      
      // Close modal and reset form
      handleCloseEdit();
      
      // Fetch updated data
      await fetchDetails();
      
      // Show success message (optional)
      setError(""); // Clear any existing errors
    } catch (err) {
      setError("Failed to update details. Please try again.");
      setLoading(false);
    }
  };

  // Form validation
  const validateForm = () => {
    const errors = {};

    if (!editDetails.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{10,11}$/.test(editDetails.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 10-11 digits.";
    }

    if (!editDetails.supportEmail.trim()) {
      errors.supportEmail = "Support email is required.";
    } else if (!isValidEmail(editDetails.supportEmail)) {
      errors.supportEmail = "Please enter a valid email address.";
    }

    if (editDetails.minPrice < 0) {
      errors.minPrice = "Minimum price cannot be negative.";
    }

    if (editDetails.maxPrice < 0) {
      errors.maxPrice = "Maximum price cannot be negative.";
    }

    if (Number(editDetails.maxPrice) <= Number(editDetails.minPrice)) {
      errors.maxPrice = "Maximum price must be greater than minimum price.";
    }

    setEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  if (loading) return <p>Loading details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="container">
          <div className="row foot-tablerow">
            {canEditGeneralDetails && <div className="heading-and-button">
              <h5>General Details</h5>
              <div className="add-button">
                <button onClick={handleShowEdit}>Edit Details</button>
              </div>
            </div>}
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4">
                <div className="details-card">
                  <div className="detail-item">
                    <strong>Phone Number:</strong>
                    <span>{details?.phoneNumber}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Support Email:</strong>
                    <span>{details?.supportEmail}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Minimum Price:</strong>
                    <span>₹{details?.minPrice}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Maximum Price:</strong>
                    <span>₹{details?.maxPrice}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Details Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateDetails}>
              {error && <div className="alert alert-danger">{error}</div>}

              {/* Phone Number Field */}
              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className={`form-control ${editErrors.phoneNumber ? "is-invalid" : ""}`}
                  name="phoneNumber"
                  value={editDetails.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.phoneNumber && (
                  <div className="invalid-feedback">{editErrors.phoneNumber}</div>
                )}
              </div>

              {/* Support Email Field */}
              <div className="mb-3">
                <label className="form-label">Support Email</label>
                <input
                  type="email"
                  className={`form-control ${editErrors.supportEmail ? "is-invalid" : ""}`}
                  name="supportEmail"
                  value={editDetails.supportEmail}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.supportEmail && (
                  <div className="invalid-feedback">{editErrors.supportEmail}</div>
                )}
              </div>

              {/* Min Price Field */}
              <div className="mb-3">
                <label className="form-label">Minimum Price</label>
                <input
                  type="number"
                  className={`form-control ${editErrors.minPrice ? "is-invalid" : ""}`}
                  name="minPrice"
                  value={editDetails.minPrice}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.minPrice && (
                  <div className="invalid-feedback">{editErrors.minPrice}</div>
                )}
              </div>

              {/* Max Price Field */}
              <div className="mb-3">
                <label className="form-label">Maximum Price</label>
                <input
                  type="number"
                  className={`form-control ${editErrors.maxPrice ? "is-invalid" : ""}`}
                  name="maxPrice"
                  value={editDetails.maxPrice}
                  onChange={handleInputChange}
                  required
                />
                {editErrors.maxPrice && (
                  <div className="invalid-feedback">{editErrors.maxPrice}</div>
                )}
              </div>

              {/* Modal Footer Buttons */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default DetailsEdit;