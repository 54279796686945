import React, { useState } from "react";
import axiosClient from "../../axios-client"
import { Modal } from "react-bootstrap";

function NewsLetter() {
  const [email, setEmail] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  // Handle email input change
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Email validation regex
    const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,7}$/;
  
    if (!email || !emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
  
    setError(""); // Clear any previous error
  
    try {
      const response = await axiosClient.post("/news", { email });
  
      if (response.status === 201) {
        // Show success modal
        setModalMessage("Thank you for subscribing to our newsletter!");
        setShowModal(true);
        setEmail(""); // Reset email input
      } else {
        // Handle other response statuses if necessary
        setModalMessage("Subscription failed. Please try again.");
        setShowModal(true);
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Check if the error message matches "Email is already subscribed"
        if (err.response.data.message === "Email is already subscribed") {
          setModalMessage("This email is already subscribed to our newsletter.");
        } else {
          setModalMessage("An error occurred. Please try again later.");
        }
      } else {
        setModalMessage("An error occurred. Please try again later.");
      }
      setShowModal(true);
    }
  };
  

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-primary1 py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-14 lg:px-20">
        <div className="flex flex-col items-center rounded-lg py-4 sm:py-8 lg:flex-row lg:justify-between lg:items-center">
          {/* Title Section */}
          <div className="mb-4 sm:mb-8 lg:mb-0 lg:flex-1">
            <h2 className="text-center text-xl font-bold font-museo sm:text-2xl lg:text-left lg:text-3xl text-white w-full lg:w-3/4">
              Join Our Newsletter to Keep Up To Date With Us!
            </h2>
          </div>

          {/* Input Section */}
          <div className="flex flex-col items-center w-full lg:w-auto lg:flex-1 lg:items-end">
            <form className="flex w-full max-w-md gap-2" onSubmit={handleSubmit}>
              <input
                placeholder="Enter your Email"
                value={email}
                onChange={handleInputChange}
                className="bg-white w-full flex-1 rounded-lg px-3 py-3 text-gray-800 placeholder-gray-400 outline-none ring-indigo-300 transition duration-100 focus:ring"
                required
              />
              <button className="inline-block font-museo rounded-lg btn-color px-4 sm:px-6 lg:px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-red-600 focus-visible:ring active:bg-red-700 md:text-base">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
        {/* Success/Error Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <div className="text-center text-xl font-bold py-4 font-museo">
            <h4>{modalMessage}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="inline-block font-museo btn-color px-4 sm:px-6 py-2 text-center text-sm font-semibold text-white rounded-lg outline-none transition duration-100 hover:bg-red-600 active:bg-red-700"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewsLetter;
