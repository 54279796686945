import React, { useState, useEffect } from "react";

const CustomDropdown = ({ categories, testCategory, setTestCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(testCategory);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelection = (categoryId) => {
    setSelectedCategories((prevSelected) => {
      const isSelected = prevSelected.includes(categoryId);
      if (isSelected) {
        // Remove the category from selected if already present
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        // Add the category to selected
        return [...prevSelected, categoryId];
      }
    });
  };
  useEffect(() => {
    setTestCategory(selectedCategories);
  }, [selectedCategories, setTestCategory]);

  return (
    <div className="col-lg-6 mb-3">
      <label className="form-label">Test Category</label>
      {/* Display Selected Categories */}
      {selectedCategories?.length > 0 && (
        <div className="selected-categories mb-3 d-flex gap-3">
          {categories
            .filter((category) => selectedCategories.includes(category._id))
            .map((selectedCategory) => (
              <p key={selectedCategory._id} className="text-sm text-gray-700">
                {selectedCategory.name}
              </p>
            ))}
        </div>
      )}

      {/* Custom Dropdown */}
      <div className="relative">
        <div
          className="w-full px-4 py-2 text-left border rounded-md "
          onClick={toggleDropdown}
        >
          Select Category
        </div>

        {isOpen && (
          <div className="absolute left-0 w-full mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
            {categories.map((category) => (
              <label
                key={category._id}
                className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-indigo-100"
              >
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category._id)}
                  onChange={() => handleSelection(category._id)}
                  className="mr-2"
                />
                {category.name}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
