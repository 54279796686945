import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const DoctorsEdit = () => {
  const dataTableRef = useRef();
  const { filepath } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [editDoctor, setEditDoctor] = useState({
    name: "",
    role: "",
    location: "",
    review: "",
    doctorImage: null, // For file uploads
  });
  const [newDoctor, setNewDoctor] = useState({
    name: "",
    role: "",
    location: "",
    review: "",
    doctorImage: null, // For file uploads
  });
  const navigate = useNavigate(); // Initialize useNavigate
  const checkPermission = useCheckPermission();

  const canEditDoctor = checkPermission(ADMIN_MODULES.DOCTOR, PERMISSIONS.EDIT);
  const canDeleteDoctor = checkPermission(ADMIN_MODULES.DOCTOR, PERMISSIONS.DELETE);
  const canAddDoctor = checkPermission(ADMIN_MODULES.DOCTOR, PERMISSIONS.ADD);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  // Fetch all doctors on component mount
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axiosClient.get("docs");

        if (response.data.success) {
          setDoctors(response.data.doctors);
          setLoading(false);

          // Initialize DataTable after data is loaded
          if (dataTableRef.current) {
            $(dataTableRef.current).DataTable();
          }
        } else {
          throw new Error("Failed to fetch doctors.");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch doctors. Please try again.");
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  // Handle showing the edit modal with selected doctor data
  const handleShowEdit = (doctor) => {
    setSelectedDoctorId(doctor?._id);
    setEditDoctor({
      name: doctor?.name || "",
      role: doctor?.role || "",
      location: doctor?.location || "",
      review: doctor?.review || "",
      doctorImage: null, // Reset file input
    });
    setShowEditModal(true);
  };

  // Handle closing the edit modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
  };

  // Handle input changes in edit form
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "doctorImage") {
      setEditDoctor((prev) => ({
        ...prev,
        doctorImage: files[0], // Assuming single file upload
      }));
    } else {
      setEditDoctor((prev) => ({
        ...prev,
        [name]: name === "review" ? value.slice(0, 250) : value,
      }));
    }
  };

  // Update doctor details
  const updateDoctor = async (e) => {
    e.preventDefault();
    setError(""); // Reset error state

    try {
      const formData = new FormData();
      formData.append("name", editDoctor.name);
      formData.append("role", editDoctor.role);
      formData.append("location", editDoctor.location);
      formData.append("review", editDoctor.review);
      if (editDoctor.doctorImage) {
        formData.append("doctorImage", editDoctor.doctorImage);
      }

      const response = await axiosClient.put(
        `docs/${selectedDoctorId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        // Update the doctors list with the edited doctor
        setDoctors((prevDoctors) =>
          prevDoctors.map((doctor) =>
            doctor._id === selectedDoctorId
              ? { ...doctor, ...response.data.doctor }
              : doctor
          )
        );
        setShowEditModal(false);
      } else {
        throw new Error("Failed to update doctor.");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to update doctor. Please try again."
      );
    }
  };

  // Show the delete confirmation modal
  const handleShowDelete = (doctorId) => {
    setSelectedDoctorId(doctorId);
    setShowDeleteModal(true);
  };

  // Handle closing the delete modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Handle doctor deletion
  const deleteDoctor = async () => {
    try {
      const response = await axiosClient.delete(`docs/${selectedDoctorId}`);

      if (response.data.success) {
        setDoctors((prevDoctors) =>
          prevDoctors.filter((doctor) => doctor._id !== selectedDoctorId)
        );
        setShowDeleteModal(false);
      } else {
        throw new Error("Failed to delete doctor.");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to delete the doctor. Please try again."
      );
      setShowDeleteModal(false);
    }
  };

  // Show Add Doctor Modal
  const handleShowAdd = () => {
    setNewDoctor({
      name: "",
      role: "",
      location: "",
      review: "",
      doctorImage: null,
    });
    setError("");
    setShowAddModal(true);
  };

  // Close Add Doctor Modal
  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
  };

  // Handle input changes in Add Doctor form
  const handleAddInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "doctorImage") {
      setNewDoctor((prev) => ({
        ...prev,
        doctorImage: files[0], // Assuming single file upload
      }));
    } else {
      setNewDoctor((prev) => ({
        ...prev,
        [name]: name === "review" ? value.slice(0, 250) : value,
      }));
    }
  };
  // Submit new doctor
  const addDoctor = async (e) => {
    e.preventDefault();
    setError(""); // Reset error state
    try {
      const formData = new FormData();
      formData.append("name", newDoctor.name);
      formData.append("role", newDoctor.role);
      formData.append("location", newDoctor.location);
      formData.append("review", newDoctor.review);
      if (newDoctor.doctorImage) {
        formData.append("doctorImage", newDoctor.doctorImage);
      }

      const response = await axiosClient.post("docs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        const createdDoctor = response.data.doctor;
        setDoctors([...doctors, createdDoctor]);
        setShowAddModal(false);
      } else {
        throw new Error("Failed to add new doctor.");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to add new doctor. Please try again."
      );
    }
  };

  if (loading) return <p>Loading doctors...</p>;
  if (error) return <p className="text-danger">{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this doctor?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteDoctor}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Doctor Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateDoctor}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={editDoctor.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  name="role"
                  value={editDoctor.role}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={editDoctor.location}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review</label>
                <textarea
                  className="form-control"
                  name="review"
                  value={editDoctor.review}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Doctor Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="doctorImage"
                  onChange={handleInputChange}
                  accept="image/*"
                />
                {/* Optionally display current image */}
                {/* {currentDoctor.doctorImage && (
                  <img
                    src={`/path/to/images/${currentDoctor.doctorImage}`}
                    alt="Doctor"
                    className="mt-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                )} */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Add Doctor Modal */}
        <Modal show={showAddModal} onHide={handleCloseAdd} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addDoctor}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={newDoctor.name}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  name="role"
                  value={newDoctor.role}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={newDoctor.location}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review</label>
                <textarea
                  className="form-control"
                  name="review"
                  value={newDoctor.review}
                  onChange={handleAddInputChange}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Doctor Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="doctorImage"
                  onChange={handleAddInputChange}
                  accept="image/*"
                  required
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseAdd}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Add Doctor
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Doctor Details</h5>
              {canAddDoctor && <div className="add-button" style={{ marginBottom: "20px" }}>
                <button onClick={handleShowAdd} className="btn btn-primary">
                  + Add Doctor
                </button>
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Location</th>
                      {/* <th>Review</th> */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctors.map((doctor, index) => (
                      <tr key={doctor._id}>
                        <td>{index + 1}</td>
                        <td>
                          {doctor.doctorImage ? (
                            <img
                              src={`${filepath}doctors/${doctor.doctorImage}`}
                              alt={doctor.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td>{doctor.name}</td>
                        <td>{doctor.role}</td>
                        <td>{doctor.location}</td>
                        {/* <td>{doctor.review}</td> */}
                        <td>
                          {canDeleteDoctor && <i
                            className="fa-solid fa-trash mr-2 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowDelete(doctor._id)}
                            title="Delete Doctor"
                          ></i>}
                          {canEditDoctor &&<i
                            className="fa-solid fa-pen-to-square text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowEdit(doctor)}
                            title="Edit Doctor"
                          ></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorsEdit;
