import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SideBar from "./SideBar";
import { Navbar } from "react-bootstrap";
import iziToast from 'izitoast';
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

function PrivacyPolicyEditor() {
  const [content, setContent] = useState("");

  const checkPermission = useCheckPermission();

  const canEditPolicy = checkPermission(ADMIN_MODULES.POLICY, PERMISSIONS.EDIT);


  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axiosClient.get("/privacy-policy");
        setContent(response?.data?.privacyPolicy?.content || "");
      } catch (err) {
        if (err.response && err.response.status === 404) {
          console.warn("No privacy policy content found. Creating new content.");
          setContent(""); 
        } else {
          console.error("Error fetching privacy policy:", err);
        }
      }
    };

    fetchPrivacyPolicy();
  }, []);

  const handleSave = async () => {
    try {
      await axiosClient.post("/privacy-policy", { content });
      iziToast.success({
        message: "Privacy Policy updated successfully!",
        position: "topCenter"
      });
    } catch (err) {
      console.error("Error saving privacy policy:", err);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Privacy Policy Details</h5>
              {canEditPolicy && <div className="add-button">
                <button onClick={handleSave}>Save</button>
              </div>}
            </div>
            <div className="col-lg-12">
              <ReactQuill value={content} onChange={setContent} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicyEditor;
