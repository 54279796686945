const ADMIN_MODULES = {
    USERS: 'users',
    LABS: 'labs',
    DOCTOR: 'doctor',
    CUSTOMER_REVIEW: 'customerReview',
    NEWSLETTER: 'newsLetter',
    GENERAL_DETAILS: 'generalDetails',
    GIFT_CARD: 'giftCard',
    CONTACT_US: 'contactUs',
    TEST_BOOKING: 'testBooking',
    TEST: 'test',
    POLICY: 'policy',
  };
  
  const PERMISSIONS = {
    VIEW: 'view',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
  };
  
  export { ADMIN_MODULES, PERMISSIONS };
  