import { useEffect, useState } from "react";
import CompletedTests from "./CompletedTests";
import TrackProgress from "./TrackProgress";
import { useNavigate } from "react-router-dom";

const BookingsAndReports = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register");
    }
  }, [navigate]);

  const tabs = [
    { name: "Upcoming Tests", value: "upcoming" },
    { name: "Past Tests", value: "past" },
    { name: "Completed Tests", value: "completed" },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "upcoming":
        return <TrackProgress />;
      case "past":
        return <PastTests />;
      case "completed":
        return <CompletedTests />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 md:p-6 lg:p-8">
      {/* Tabs Navigation */}
      <div className="border-b border-gray-200 my-6">
        <nav
          className="flex flex-wrap justify-center md:justify-start space-x-2 sm:space-x-4 overflow-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`whitespace-nowrap pb-4 py-2 px-4 text-sm md:text-lg font-semibold transition-colors duration-300 ${
                activeTab === tab.value
                  ? "border-b-2 border-colorb font-museo text-gray-800"
                  : "text-gray-500 hover:text-gray-800"
              }`}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Tab Content */}
      <div className="mt-6">
        <div className="w-full overflow-auto">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

const PastTests = () => (
  <div className="text-center text-lg font-semibold text-gray-700">
    No past tests available.
  </div>
);

export default BookingsAndReports;
