import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import axiosClient from "../../axios-client";

const AddPatientsCart = ({
  isEditing,
  isAdding,
  setIsEditing,
  setIsAdding,
  currentPatient,
  onRefresh,
  onRemove,
}) => {
  const [currentPatientData, setCurrentPatientData] = useState(null);
  const relationships = [
    "Father",
    "Mother",
    "Son",
    "Daughter",
    "Spouse",
    "Sibling",
    "Self",
  ];
  // Use useEffect to update currentPatientData whenever currentPatient changes
  useEffect(() => {
    setCurrentPatientData(currentPatient);
  }, [currentPatient]); // Dependency array includes currentPatient

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCurrentPatientData((prev) => {
      let updatedData = { ...prev, [name]: value };

      if (name === "dob") {
        const age = calculateAge(value);
        updatedData.age = age;
      }

      return updatedData;
    });
  };

  const handleSave = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID is missing");
        return;
      }

      // console.log("Current Patient Data Before Submit:", currentPatientData); // Log patient data

      const dob = new Date(currentPatientData.dob); // Create date object

      // Check if dob is valid
      if (isNaN(dob.getTime())) {
        console.error("Invalid date of birth");
        return;
      }

      const formattedDOB = new Date(dob).toISOString();

      // Prepare data to send
      const dataToSend = {
        ...currentPatientData,
        userId,
        dob: formattedDOB,
      };

      // Logging the data that is being sent to the API
      // console.log("Data to send:", dataToSend);

      if (isEditing) {
        const response = await axiosClient.put(
          `/users/family-member/${currentPatientData._id}`,
          dataToSend
        );
        // console.log("Patient updated successfully:", response.data);
        setIsEditing(false);
      } else if (isAdding) {
        const response = await axiosClient.post(
          `/users/family-member`,
          dataToSend
        );
        // console.log("Patient added successfully:", response.data);
        setIsAdding(false);
      }
      onRefresh();
    } catch (error) {
      console.error(
        "Error saving patient:",
        error.response?.data || error.message
      );
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleRemove = async () => {
    if (currentPatientData?._id) {
      // Just call the remove handler - no database deletion
      onRemove(currentPatientData._id);

      // Close the modal
      setIsEditing(false);
      setIsAdding(false);
    }
  };
  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday's date
  const yesterdayString = today.toISOString().split("T")[0];

  return (
    <Dialog
      open={isEditing || isAdding}
      onClose={() => {
        setIsEditing(false);
        setIsAdding(false);
      }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
    >
      <Dialog.Panel className="bg-white rounded-lg shadow-md w-full max-w-lg">
        <Dialog.Title className="flex justify-between items-center text-xl font-bold mb-4 font-museo border-b-2 px-6 py-4">
          <span>{isEditing ? "Edit Patient" : "Add New Patient"}</span>
          <button
            onClick={() => {
              setIsEditing(false);
              setIsAdding(false);
            }}
            className="text-gray-500 hover:text-gray-700 ml-auto" // Added ml-auto to push button to the right
          >
            <RxCross2 className="h-6 w-6" aria-hidden="true" />
          </button>
        </Dialog.Title>

        <div className="grid grid-cols-2 gap-4 mx-6">
          <div className="col-span-2">
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="name"
            >
              Name
            </label>
            <input
              name="name"
              value={currentPatientData?.name || ""}
              onChange={handleInputChange}
              placeholder="Name"
              className="w-full col-span-2 p-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="relation"
            >
              Relation
            </label>
            <select
              name="relation"
              id="relation"
              value={currentPatientData?.relation || ""}
              onChange={handleInputChange}
              placeholder="Relation"
              className="w-full p-2 border rounded-lg"
            >
              <option value="">Select Relationship</option>
              {relationships.map((relation) => (
                <option key={relation} value={relation}>
                  {relation}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="dob"
            >
              Date of Birth
            </label>
            <input
              name="dob"
              type="date"
              value={currentPatientData?.dob || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-lg"
              required
              max={yesterdayString}
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="age"
            >
              Age(Years)
            </label>
            <input
              name="age"
              type="number" // Added type for number input
              value={currentPatientData?.age || ""}
              onChange={handleInputChange}
              placeholder="Age"
              className="w-full p-2 border rounded-lg"
              min="0"
              required
              readOnly
            />
          </div>

          {/* Gender Input */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="gender"
            >
              Gender
            </label>
            <select
              name="gender"
              value={currentPatientData?.gender || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-lg"
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="phone"
            >
              Phone Number
            </label>
            <input
              name="phone"
              value={currentPatientData?.phone || ""}
              onChange={handleInputChange}
              placeholder="Phone"
              className="w-full p-2 border rounded-lg"
              type="tel" // Optional but can enhance user experience on mobile devices
              pattern="^\d{10}$" // Ensures only 10 digits
              maxLength="10" // Restricts input to a maximum of 10 characters
              title="Phone number should be exactly 10 digits"
              minLength="10" // Ensures the number is exactly 10 digits
              required
            />
          </div>
          {/* Email Input */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="email"
            >
              Email
            </label>
            <input
              name="email"
              type="email" // Added type for email input
              value={currentPatientData?.email || ""}
              onChange={handleInputChange}
              placeholder="Email"
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        <div className="flex justify-between mt-5 border-t-2 pt-4 mb-4 font-mont">
          {isEditing && (
            <button
              onClick={handleRemove}
              className=" text-white px-4 py-2 rounded-md hover:bg-blue-600 font-museo ml-2 bg-secondary1"
            >
              Delete Patient
            </button>
          )}
          <div className="ml-auto">
            <button
              onClick={handleSave}
              className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600 font-museo mx-2 font-mont"
            >
              {isEditing ? "Save Changes" : "Add New Patient"}
            </button>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default AddPatientsCart;
