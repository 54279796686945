import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const CustomerReviewEdit = () => {
  const dataTableRef = useRef();
  const { filepath } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [editReview, setEditReview] = useState({
    name: "",
    role: "",
    review: "",
    image: null,
  });
  const [newReview, setNewReview] = useState({
    name: "",
    role: "",
    review: "",
    image: null,
  });
  const navigate = useNavigate();
  const checkPermission = useCheckPermission();

  const canEditCustomerReview = checkPermission(ADMIN_MODULES.CUSTOMER_REVIEW, PERMISSIONS.EDIT);
  const canDeleteCustomerReview = checkPermission(ADMIN_MODULES.CUSTOMER_REVIEW, PERMISSIONS.DELETE);
  const canAddCustomerReview = checkPermission(ADMIN_MODULES.CUSTOMER_REVIEW, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchReviews = async () => {
    try {
      const response = await axiosClient.get("review");

      if (response.data.success) {
        setReviews(response.data.reviews);
        setLoading(false);

        // Initialize DataTable after data is loaded
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } else {
        throw new Error("Failed to fetch customer reviews.");
      }
    } catch (err) {
      setError(
        err.message || "Failed to fetch customer reviews. Please try again."
      );
      setLoading(false);
    }
  };

  // Fetch all reviews on component mount
  useEffect(() => {
    fetchReviews();
  }, []);

  // Handle showing the edit modal with selected review data
  const handleShowEdit = (review) => {
    setSelectedReviewId(review?._id);
    setEditReview({
      name: review?.name || "",
      role: review?.role || "",
      review: review?.review || "",
      image: null,
    });
    setShowEditModal(true);
  };

  // Handle closing the edit modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
    fetchReviews();
  };

  // Handle input changes in edit form
  const handleEditInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setEditReview((prev) => ({
        ...prev,
        image: files[0], // Assuming single file upload
      }));
    } else {
      setEditReview((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Update review details
  const updateReview = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const formData = new FormData();
      formData.append("name", editReview.name);
      formData.append("role", editReview.role);
      formData.append("review", editReview.review);
      if (editReview.image) {
        formData.append("image", editReview.image);
      }

      const response = await axiosClient.put(
        `review/${selectedReviewId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        // Update the reviews list with the edited review
        setReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === selectedReviewId
              ? { ...review, ...response.data.review }
              : review
          )
        );
        setShowEditModal(false);
      } else {
        throw new Error("Failed to update customer review.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to update customer review. Please try again."
      );
    }
  };

  // Show the delete confirmation modal
  const handleShowDelete = (reviewId) => {
    setSelectedReviewId(reviewId);
    setShowDeleteModal(true);
    fetchReviews();
  };

  // Handle closing the delete modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Handle review deletion
  const deleteReview = async () => {
    try {
      const response = await axiosClient.delete(`review/${selectedReviewId}`); // Updated endpoint

      if (response.data.success) {
        setReviews((prevReviews) =>
          prevReviews.filter((review) => review._id !== selectedReviewId)
        );
        setShowDeleteModal(false);
      } else {
        throw new Error("Failed to delete customer review.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to delete the customer review. Please try again."
      );
      setShowDeleteModal(false);
    }
  };

  // Show Add Review Modal
  const handleShowAdd = () => {
    setNewReview({
      name: "",
      role: "",
      review: "",
      image: null,
    });
    setError("");
    setShowAddModal(true);
  };

  // Close Add Review Modal
  const handleCloseAdd = () => {
    setShowAddModal(false);
    setError("");
    fetchReviews();
  };

  // Handle input changes in Add Review form
  const handleAddInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setNewReview((prev) => ({
        ...prev,
        image: files[0], // Assuming single file upload
      }));
    } else {
      setNewReview((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Submit new review
  const addReview = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const formData = new FormData();
      formData.append("name", newReview.name);
      formData.append("role", newReview.role);
      formData.append("review", newReview.review);
      if (newReview.image) {
        formData.append("image", newReview.image);
      }

      const response = await axiosClient.post("review", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        const createdReview = response.data.review;
        setReviews([...reviews, createdReview]);
        setShowAddModal(false);
      } else {
        throw new Error("Failed to add new customer review.");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to add new customer review. Please try again."
      );
    }
  };

  // Initialize DataTable on reviews update
  useEffect(() => {
    if (dataTableRef.current && !loading && !error) {
      $(dataTableRef.current).DataTable({
        destroy: true, // Allows reinitialization
      });
    }
  }, [reviews, loading, error]);

  if (loading) return <p>Loading customer reviews...</p>;

  if (error) return <p className="text-danger">{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Customer Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this customer review?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteReview}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Review Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Customer Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateReview}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={editReview.name}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  name="role"
                  value={editReview.role}
                  onChange={handleEditInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review</label>
                <textarea
                  className="form-control"
                  name="review"
                  value={editReview.review}
                  onChange={handleEditInputChange}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Customer Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={handleEditInputChange}
                  accept="image/*"
                />
                {/* Display current image if available */}
                {/* Assuming you pass the current image URL as a prop or have access to it */}
                {/* Example:
                {currentReview.image && (
                  <img
                    src={`${filepath}customerReviews/${currentReview.image}`}
                    alt="Customer"
                    className="mt-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                )} */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Add Review Modal */}
        <Modal show={showAddModal} onHide={handleCloseAdd} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Customer Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addReview}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={newReview.name}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  name="role"
                  value={newReview.role}
                  onChange={handleAddInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review</label>
                <textarea
                  className="form-control"
                  name="review"
                  value={newReview.review}
                  onChange={handleAddInputChange}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Customer Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={handleAddInputChange}
                  accept="image/*"
                  required
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseAdd}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Add Review
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Customer Reviews</h5>
              {canAddCustomerReview && <div className="add-button" style={{ marginBottom: "20px" }}>
                <button onClick={handleShowAdd} className="btn btn-primary">
                  + Add Review
                </button>
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Review</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews.map((review, index) => (
                      <tr key={review._id}>
                        <td>{index + 1}</td>
                        <td>
                          {review.image ? (
                            <img
                              src={`${filepath}customerReviews/${review.image}`}
                              alt={review.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td>{review.name}</td>
                        <td>{review.role}</td>
                        <td>{review.review}</td>
                        <td>
                          {canDeleteCustomerReview && <i
                            className="fa-solid fa-trash mr-2 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowDelete(review._id)}
                            title="Delete Review"
                          ></i>}
                          {canEditCustomerReview && <i
                            className="fa-solid fa-pen-to-square text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowEdit(review)}
                            title="Edit Review"
                          ></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerReviewEdit;
