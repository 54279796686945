import { useEffect, useState } from "react";
import ModalCart from "./ModalCart";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client"; // Ensure axiosClient is correctly imported
import { useAuth } from "../../AuthContext";

const AddPatients = () => {
  // State variables for booking, tests, patients, modals, pagination, loading, and errors
  const [booking, setBooking] = useState(); // Holds booking data
  const [tests, setTests] = useState([]); // Holds fetched tests
  const [patients, setPatients] = useState([]); // Holds fetched patients
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditPackageModalOpen, setEditPackageModalOpen] = useState(false);
  const [isEditAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [isEditDateTimeModalOpen, setEditDateTimeModalOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [error, setError] = useState(null); // Tracks error messages
  const [labAddress, setLabAddress] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [physicalCopy, setPhysicalCopy] = useState(false);
  const navigate = useNavigate();
  const { cart, removeFromCart } = useAuth();
  const [baseTotalAmount, setBaseTotalAmount] = useState(0);
  const [giftCode, setGiftCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [matchedDetails, setMatchedDetails] = useState([]);
  const patientsPerPage = 3; // Number of patients per page for pagination
  const [giftCardId, setGiftCardId]= useState("");

  // Modal control functions
  // const openEditModalForPackage = (pkg) => {
  //   setCurrentPackage(pkg);
  //   setEditPackageModalOpen(true);
  // };

  // const openEditModal = (patient) => {
  //   setCurrentPatient(patient);
  //   setEditModalOpen(true);
  // };

  // const openEditAddressModal = () => {
  //   setEditAddressModalOpen(true);
  // };

  // const openEditDateTimeModal = () => {
  //   setEditDateTimeModalOpen(true);
  // };

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setEditPackageModalOpen(false);
    setEditAddressModalOpen(false);
    setEditDateTimeModalOpen(false);
    setCurrentPatient(null);
    setCurrentPackage(null);
  };
  const bookingId = localStorage.getItem("bookingId");

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  // Fetch booking, tests, and patients data on component mount
  useEffect(() => {
    const fetchBookingData = async () => {
      setLoading(true);
      setError(null); // Reset any previous errors

      try {
        const bookingId = localStorage.getItem("bookingId");
        const userId = localStorage.getItem("userId");

        if (!userId) {
          throw new Error("User ID not found in local storage.");
        }

        // Fetch Patient Data
        const response = await axiosClient(`/users/details/${userId}`);
        const data = await response.data;
        if (data.success) {
          console.log("Fetched family members:", data.user.familyMembers);
          setPatients(data.user.familyMembers); // Set familyMembers as patients
        }
        const familyMembers = data.user.familyMembers;

        if (!bookingId) {
          throw new Error("Booking ID not found in local storage.");
        }

        // Fetch Booking Data
        const bookingResponse = await axiosClient.get(
          `/tests-booking/test/booking/${bookingId}`
        );
        if (!bookingResponse.data.success) {
          throw new Error("Failed to fetch booking data.");
        }

        const bookingData = bookingResponse.data.booking;
        console.log("Booking familyMembers_id:", bookingData.familyMembers_id);
        setBooking(bookingData);
        setBaseTotalAmount(bookingData.totalAmount);

        // Check if booking member IDs match family member IDs and store details
        const bookingMembers = bookingData.familyMembers_id || [];
        const matchedMembers = familyMembers.filter((member) =>
          bookingMembers.includes(member._id)
        );

        if (matchedMembers.length > 0) {
          const matchedDetails = matchedMembers.map((member) => ({
            name: member.name,
            age: member.age,
            gender: member.gender,
            phone: member.phone,
          }));

          console.log("Matched Members Details:", matchedDetails);

          // You can store these details in state if needed
          setMatchedDetails(matchedDetails); // Ensure `setMatchedDetails` is a state setter function
        } else {
          console.log("No matching family members found.");
        }

        // Fetch tests data if available
        if (bookingData.test_id && bookingData.test_id.length > 0) {
          const testPromises = bookingData.test_id.map((id) =>
            axiosClient.get(`/tests/test/${id}`)
          );
          const testResponses = await Promise.all(testPromises);
          const fetchedTests = testResponses.map((res) => res.data.test); // Adjust based on actual API response
          setTests(fetchedTests);
        }
      } catch (err) {
        console.error("Error fetching booking data:", err);
        setError(
          err.response?.data?.message ||
            err.message ||
            "An error occurred while fetching data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBookingData();
  }, []);

  useEffect(() => {
    const fetchLabAddress = async () => {
      if (booking && booking.labAddress) {
        try {
          const response = await axiosClient.get(`/labs/${booking.labAddress}`);
          if (response.data && response.data.lab) {
            setLabAddress(response.data.lab);
          }
        } catch (error) {
          console.error("Error fetching lab address:", error);
          setError("Failed to fetch lab address");
        }
      }
    };

    fetchLabAddress();
  }, [booking]);

  const handlePhysicalCopyChange = (isChecked) => {
    setPhysicalCopy(isChecked);
    setBooking((prevBooking) => ({
      ...prevBooking,
      totalAmount: isChecked ? baseTotalAmount + 150 : baseTotalAmount,
    }));
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleConfirmAndPay = async () => {
    const userId = localStorage.getItem("userId");

    const userDetail = await axiosClient.get(`/users/details/${userId}`);
    // console.log("get data number", userDetail.data.user.phone);
    const FName = userDetail.data.user.firstName;
    const LName = userDetail.data.user.lastName;
    const FullName = FName + " " + LName;
    const MNumber = userDetail.data.user.phone;
    const bookingId = localStorage.getItem("bookingId");

    const amt = booking.totalAmount;

    const orderId = `${bookingId}${Date.now()}`;
    // console.log(orderId);

    const muid = "MUID" + Date.now();
    const tID = "TID" + Date.now();
    localStorage.setItem("TransactionID", tID);
    localStorage.setItem("muid", muid);
    const BookingDetails = await axiosClient.get(
      `/tests-booking/test/booking/${bookingId}`
    );
    const data = {
      MUID: muid,
      transactionId: tID,
      amount: amt,
      name: FullName,
      number: MNumber,
      user_id: BookingDetails.data.booking.user_id,
      serviceType: "",
      paymentMethod: "Online",
      paymentStatus: "",
      bookingStatus: "",
      testDate: BookingDetails.data.booking.testDate,
      timeSlot: BookingDetails.data.booking.timeSlot,
      test_id: BookingDetails.data.booking.test_id,
      familyMembers_id: BookingDetails.data.booking.familyMembers_id,
      isHome: BookingDetails.data.booking.isHome,
      homeAddress: BookingDetails.data.booking.homeAddress,
      labAddress: BookingDetails.data.booking.labAddress,
      bookingDetails: bookingId,
      physicalCopy: physicalCopy,
      totalAmount: BookingDetails.data.booking.totalAmount,
    };
    // console.log("data", data);

    try {
      const res = await axiosClient.post(`/payment/order`, data);
      // console.log(res);

      if (res.data.success && res.data.data.instrumentResponse.redirectInfo) {
        const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url;
        // console.log(res.data.data.instrumentResponse.redirectInfo.url);
        // Redirect user to the payment page
        // localStorage.setItem("transactionId", tID); // Store transaction ID

        window.location.href = redirectUrl; // Redirects to PhonePe payment page

        // if(giftCardId){
        //   await axiosClient.post("/markGiftCardUsed", {
        //     userId: userId,
        //     giftCardId: giftCardId
        //   });
        //   setGiftCardId("");
        // }
      } else {
        console.error("Payment not initiated:", res.data.message);
      }
    } catch (error) {
      console.error("Error creating payment order:", error);
    }
  };

  const handlePaymentSelection = (method) => {
    setPaymentMethod(method);
    handlePaymentMethodChange(method);
    setError(""); // Clear error when payment method is selected
  };

  useEffect(() => {
    const calculateTotalAmount = () => {
      // Start with base amount
      let newTotal = baseTotalAmount;

      // Add physical copy charge if selected
      if (physicalCopy) {
        newTotal += 150;
      }

      // Subtract discount if any
      if (discount > 0) {
        newTotal -= discount;
      }

      // Update booking with new total
      setBooking((prev) => ({
        ...prev,
        totalAmount: Math.max(0, newTotal), // Ensure total never goes below 0
      }));
    };

    calculateTotalAmount();
  }, [baseTotalAmount, physicalCopy, discount]);

  const handleButtonClick = async () => {
    if (!paymentMethod) {
      setError("Please select a payment method");
    } else if (paymentMethod === "COD") {
      const BookingDetails = await axiosClient.get(
        `/tests-booking/test/booking/${bookingId}`
      );
      // console.log("bookData", BookingDetails.data.booking.timeSlot);

      const bookData = {
        user_id: BookingDetails.data.booking.user_id,
        paymentMethod: "COD",
        paymentStatus: "Pending",
        bookingStatus: "Confirmed",
        physicalCopy: physicalCopy,
        totalAmount: booking.totalAmount,
      };

      try {
        const response = await axiosClient.put(
          `/tests-booking/test/booking/${bookingId}`,
          bookData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // console.log("Booking response:", response.data);
      } catch (error) {
        console.error("Error posting booking data:", error);
      }
      if(giftCardId){
        await axiosClient.post("gift/markGiftCardUsed", {
          userId: bookData.user_id,
          giftCardId: giftCardId
        });
        setGiftCardId("");
      }
      navigate("/congrats");
      localStorage.removeItem("bookingId");
      localStorage.removeItem("cart");
    } else {
      handleConfirmAndPay();
    }
  };

  const handleApplyGiftCard = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axiosClient.get("/gift");
      const { success, giftCards } = response.data;
  
      if (success) {
        const matchingGiftCard = giftCards.find(
          (card) => card.couponCode === giftCode
        );
  
        if (matchingGiftCard) {
          const usedResponse = await axiosClient.post("gift/checkUsedCoupon", {
            userId: userId,
            giftCardId: matchingGiftCard._id
          });
  
          if (usedResponse.data.success) {
            const discountPercentage = matchingGiftCard.percentageOff;
            const discountAmount = parseFloat(
              ((baseTotalAmount * discountPercentage) / 100).toFixed(2)
            );
  
            setDiscount(discountAmount);
            setError("");

            setGiftCardId(matchingGiftCard._id);
          } else {
            setError("You have already used this gift card.");
          }
        } else {
          setError("Invalid gift card code.");
        }
      }
    } catch (err) {
      console.error("Error fetching gift card details:", err);
      setError("Failed to apply gift card.");
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-museo">Loading...</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-10">
        {/* Left Side Container for Tests and Patients */}
        <div className="bg-white border-2 border-gray-300 p-6 lg:p-10 rounded-2xl shadow-md">
          {/* Selected Tests */}
          <h3 className="text-2xl lg:text-3xl font-bold mb-4 font-museo">
            Review <span className="font1">Tests</span>
          </h3>

          {/* Display selected tests */}
          <div className="space-y-4">
            {tests.length === 0 ? (
              <p className="font-museo">No tests selected.</p>
            ) : (
              tests.map((test) => (
                <div
                  key={test._id}
                  className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
                >
                  <div>
                    <h4 className="font-semibold font-museo font4 text-base lg:text-lg">
                      {test.title}
                    </h4>
                    <h4 className="font-semibold font-museo font4 text-base lg:text-lg">
                      {test.subTitle}
                    </h4>
                    <p className="font6 font-medium mt-2 font-museo">
                      ₹{test.price}
                    </p>
                  </div>
                  {/* <button
                    className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                    onClick={() => openEditModalForPackage(test)}
                  >
                    Edit
                  </button> */}
                </div>
              ))
            )}
          </div>

          {/* Add Patients Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo">
            Review <span className="font1">Patients Details</span>
          </h3>

          {/* Display patient items */}
          {/* Display patient items */}
          <div className="space-y-4">
            {matchedDetails.length === 0 ? (
              <p className="font-museo text-sm sm:text-base">
                No matching members found.
              </p>
            ) : (
              matchedDetails
                .slice(
                  currentPage * patientsPerPage,
                  (currentPage + 1) * patientsPerPage
                )
                .map((member, index) => (
                  <div
                    key={index} // Using index as key since matchedDetails does not guarantee unique _id
                    className="flex flex-col lg:flex-row justify-between items-start lg:items-center py-3 px-4 sm:px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 space-y-2 lg:space-y-0"
                  >
                    {/* Member Details */}
                    <div>
                      <h3 className="font-semibold font-museo text-sm sm:text-base lg:text-lg">
                        {member.name}
                      </h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2 font-museo mt-2 text-xs sm:text-sm font6 font-medium">
                        <p>
                          Age: <span className="font7">{member.age}</span>
                        </p>
                        {/* <p>
                          Email: <span className="font7">{member.email}</span>
                        </p> */}
                        <p>
                          Phone No.:{" "}
                          <span className="font7">{member.phone}</span>
                        </p>
                        <p>
                          Gender: <span className="font7">{member.gender}</span>
                        </p>
                      </div>
                    </div>

                    {/* Uncomment Edit Button if Needed */}
                    {/* <button
            className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold text-sm sm:text-base"
            onClick={() => openEditModal(member)}
          >
            Edit
          </button> */}
                  </div>
                ))
            )}
          </div>

          {/* Pagination for Patients */}
          {patients.length > patientsPerPage && (
            <div className="flex justify-center mt-4">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                disabled={currentPage === 0}
                className={`px-4 py-2 mr-2 rounded-md ${
                  currentPage === 0
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-card1 text-white"
                }`}
              >
                Previous
              </button>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    (prev + 1) * patientsPerPage < patients.length
                      ? prev + 1
                      : prev
                  )
                }
                disabled={
                  (currentPage + 1) * patientsPerPage >= patients.length
                }
                className={`px-4 py-2 rounded-md ${
                  (currentPage + 1) * patientsPerPage >= patients.length
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-card1 text-white"
                }`}
              >
                Next
              </button>
            </div>
          )}

          {/* Review Address Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo">
            Review <span className="font1">Address</span>
          </h3>

          {/* Address Display */}
          <div className="space-y-4">
            {booking.isHome ? (
              <div className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
                <div className="flex flex-col">
                  <p className="font-semibold font-museo font4 text-base lg:text-lg">
                    Home
                  </p>
                  <p className="mt-2 font-museo font7">{booking.homeAddress}</p>
                </div>
                {/* <button
                  className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                  onClick={openEditAddressModal}
                >
                  Edit
                </button> */}
              </div>
            ) : labAddress ? (
              <div className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
                <div className="flex flex-col">
                  <p className="font-semibold font-museo font4 text-base lg:text-lg">
                    {labAddress.name}
                  </p>
                  <p className="mt-2 font-museo font7">
                    {labAddress.house_no}, {labAddress.street},{" "}
                    {labAddress.landmark}, {labAddress.city}, {labAddress.state}
                    , {labAddress.pincode}
                  </p>
                </div>
                {/* <button
                  className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                  onClick={openEditAddressModal}
                >
                  Edit
                </button> */}
              </div>
            ) : (
              <p className="font-museo">Loading lab address...</p>
            )}
          </div>

          {/* Review Date & Time Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo">
            Review <span className="font1">Date & Time</span>
          </h3>

          {/* Date & Time Display */}
          <div className="space-y-4">
            {booking.testDate && booking.timeSlot ? (
              <div className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
                <div className="flex flex-col">
                  <p className="font-semibold font-museo font4 text-base lg:text-lg">
                    {formatDate(booking.testDate)}
                  </p>
                  <p className="mt-2 font-museo font7">{booking.timeSlot}</p>
                </div>
                {/* <button
                  className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                  onClick={openEditDateTimeModal}
                >
                  Edit
                </button> */}
              </div>
            ) : (
              <p className="font-museo">No date and time selected.</p>
            )}
          </div>

          {/* Get Physical Copy Checkbox */}
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="physical-copy"
              name="physical-copy"
              className="mr-3 w-4 h-4 border border-red-500 rounded focus:ring-red-500 text-red-500"
              checked={physicalCopy}
              onChange={(e) => handlePhysicalCopyChange(e.target.checked)}
            />
            <label
              htmlFor="physical-copy"
              className="ml-2 text-lg text-[#DF4132] font-museo"
            >
              Get Physical Copy of Report (Extra ₹150)
            </label>
          </div>
        </div>

        {/* Offers Section */}
        <div className="bg-white p-6 lg:p-10 rounded-2xl shadow-md border-2 border-gray-300 mt-6 lg:mt-0">
          <h3 className="text-2xl lg:text-3xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-6">
            {/* Replace with dynamic offers if available */}
            {[1, 2].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="Nurse" width={"27%"} />
                </div>
              </div>
            ))}

            {/* Gift Card Section */}
            <div className="border-2 border-gray-300 p-5 mt-5 rounded-lg">
              <h4 className="text-xl text-[#1E4FA6] font-museo font-semibold">
                Pay via Gift Card
              </h4>
              <div className="flex space-x-3">
                <input
                  type="text"
                  placeholder="Enter Code"
                  onChange={(e) => setGiftCode(e.target.value)}
                  className="w-full py-3 px-5 border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                />
                <button
                  onClick={handleApplyGiftCard}
                  className="bg-card1 text-white h-10 w-24 rounded-md font-museo font-semibold flex items-center justify-center"
                >
                  Apply
                </button>
              </div>
            </div>

            {/* Payment Method Section */}
            <div className="border-2 border-gray-300 p-6 mt-8 rounded-lg">
              <h4 className="text-xl font-semibold mb-4 text-[#1E4FA6] font-museo">
                Payment Method
              </h4>
              <div className="flex justify-center">
                <div className="flex space-x-12">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="payment"
                      value="Online"
                      className="font-museo"
                      checked={paymentMethod === "Online"}
                      onChange={() => handlePaymentSelection("Online")}
                    />
                    <span className="font-museo text-[#666363]">
                      Pay Online
                    </span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="payment"
                      value="COD"
                      className="mr-2"
                      checked={paymentMethod === "COD"}
                      onChange={() => handlePaymentSelection("COD")}
                    />
                    <span className="font-museo text-[#666363]">
                      Pay by Cash/Card
                    </span>
                  </label>
                </div>
              </div>

              {error && (
                <p className="text-red-500 mt-4 text-center font-museo">
                  {error}
                </p>
              )}

              {/* Payment Summary */}
              <div className="py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
                <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                  <p className="font7">Total Amount</p>
                  <p className="font7">₹{baseTotalAmount}</p>
                </div>
                {physicalCopy && (
                  <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                    <p className="font7">Extra Charge </p>
                    <p className="font7">₹150</p>
                  </div>
                )}
                {discount > 0 && (
                  <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                    <p className="font7">Discount applied </p>
                    <p className="font7">-₹{discount} </p>
                  </div>
                )}
                <div className="font-museo text-lg flex items-center justify-between mt-1">
                  <p className="font6">Amount to be paid </p>
                  <p className="font6">₹{booking.totalAmount}</p>
                </div>
              </div>

              {/* Confirm and Pay Button */}
              <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
                <button
                  className="bg-card1 text-white py-3 px-20 rounded-lg"
                  onClick={handleButtonClick}
                  disabled={!paymentMethod} // Disable button if no payment method selected
                >
                  {paymentMethod === "Online"
                    ? "Pay with PhonePe"
                    : "Confirm & Pay"}
                </button>
              </div>
            </div>
            {error && (
              <p className="text-red-500 mt-4 text-center font-museo">
                {error}
              </p>
            )}
          </div>

          {/* Confirm and Pay Button */}
          {/* <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <button
              className="bg-card1 text-white py-3 px-20 rounded-lg"
              onClick={handleConfirmAndPay}
              disabled={!paymentMethod}
            >
              {paymentMethod === "Online" ? "Pay with PhonePe" : "Confirm & Pay"}
            </button>
          </div> */}
        </div>
      </div>

      {/* Modal Sections */}
      {isAddModalOpen && (
        <ModalCart onClose={closeModal} title="Add Patient">
          {/* Add patient form */}
        </ModalCart>
      )}

      {isEditModalOpen && currentPatient && (
        <ModalCart
          onClose={closeModal}
          title={`Edit Patient: ${currentPatient.name}`}
        >
          {/* Edit patient form */}
        </ModalCart>
      )}

      {isEditPackageModalOpen && currentPackage && (
        <ModalCart
          onClose={closeModal}
          title={`Edit Package: ${currentPackage.name}`}
        >
          {/* Edit package form */}
        </ModalCart>
      )}

      {isEditAddressModalOpen && (
        <ModalCart onClose={closeModal} title="Edit Address">
          {/* Edit address form */}
        </ModalCart>
      )}

      {isEditDateTimeModalOpen && (
        <ModalCart onClose={closeModal} title="Edit Date & Time">
          {/* Edit date & time form */}
        </ModalCart>
      )}
    </div>
  );
};

export default AddPatients;
