import React, { useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import iziToast from "izitoast";

function Contact() {
  // State to manage form input values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    subject: "",
    message: "",
  });

  // State to manage form validation errors
  const [errors, setErrors] = useState({});

  // State to handle form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);

  // Handle form input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Form validation function
  const validate = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.phone) errors.phone = "Phone number is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";

    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const response = await axiosClient.post("contact", formData);
        if (response.status === 201) {
          setSubmissionSuccess(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            address: "",
            subject: "",
            message: "",
          });
          iziToast.success({
            message: "Your Message Sent Successfully",
            position: "topCenter"
          });
        }
      } catch (error) {
        setSubmissionSuccess(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="flex justify-center items-center py-24">
        {/* Container with responsive styles */}
        <div className="bg-white flex flex-col md:flex-row mx-4 w-full max-w-[1196px] h-auto rounded-[16px] shadow-lg shadow-gray-300 relative">
          {/* Left Side - Contact Information */}
          <div className="relative w-full md:w-[360px] lg:w-[498px] h-auto md:h-[667px] overflow-hidden">
            {/* Image with rounded corners on all screens */}
            <div
              className="absolute inset-0 w-full h-full bg-cover bg-center rounded-[16px]"
              style={{
                backgroundImage: `url('/images/Rectangle 3841.png')`,
              }}
            ></div>

            {/* Semi-transparent overlay */}
            <div className="relative z-10 p-14 flex flex-col items-center h-full">
              <div className="flex flex-col items-center mb-4 md:-mb-14 lg:-ml-24 md:ml-0">
                <h1 className="text-2xl font-bold text-white text-center mb-2 font-museo">
                  Contact Information
                </h1>
                <p className="text-gray-100 text-sm text-center montserrat lg:-ml-4 md:ml-0">
                  Say something if you have a doubt
                </p>
              </div>
              <div className="flex flex-col items-center justify-center h-full space-y-4">
                <div className="flex items-center text-white justify-between w-full">
                  <Link to={"tel:081492 02506"}>
                    <i className="fas fa-phone mr-1 flex-shrink-0 ml-5"></i>
                    <span className="flex-1 text-left ml-8 Montserrat">
                      081492 02506
                    </span>
                  </Link>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <Link to={"mailto:support@drpalvehospital.com"}>
                    <i className="fas fa-envelope mr-1 flex-shrink-0 ml-5"></i>
                    <span className="flex-1 text-left ml-8 montserrat">
                      support@drpalvehospital.com
                    </span>
                  </Link>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-map-marker-alt mr-1 flex-shrink-0 ml-5"></i>
                  <span className="flex-1 text-left ml-8">
                    Chandan Nagar, Kharadi, Pune, Maharashtra, 411014
                  </span>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-globe mr-1 flex-shrink-0 ml-5 mt-9"></i>
                  <span className="flex-1 text-left ml-8 montserrat mt-9">
                    <a
                      href="https://drpalvehospital.com"
                      className="hover:underline text-white"
                    >
                      https://drpalvehospital.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side - Get In Touch Form */}
          <div className="p-11 flex-1 flex flex-col rounded-r-[16px]">
            <h2 className="text-xl font-bold mb-2 font-museo">
              Get In Touch With Us
            </h2>
            <p className="mb-3 Montserrat">
              We are happy to answer any questions you may have regarding our
              services.
            </p>
            <form className="flex-grow flex flex-col" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mb-2 font-mont">
                <div className="mb-4">
                  <label htmlFor="name" className="block mb-1 font-mont">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-2 py-2 border-b-2 border-gray-400 focus:border-blue-600 focus:outline-none font-mont"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <span className="text-red-500 text-sm">{errors.name}</span>}
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-1 font-mont">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">
                <div className="mb-1">
                  <label htmlFor="phone" className="block mb-1 font-mont">
                    Phone No.
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none"
                    placeholder="00000-00000"
                    value={formData.phone}
                    onChange={handleChange}
                    pattern="^\d{10}$" // Ensures only 10 digits
                    maxLength="10" // Restricts input to a maximum of 10 characters
                    title="Phone number should be exactly 10 digits"
                    minLength="10" // Ensures the number is exactly 10 digits
                    required
                  />
                  {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
                </div>
                <div className="mb-4">
                  <label htmlFor="address" className="block mb-1 font-mont">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {errors.address && <span className="text-red-500 text-sm">{errors.address}</span>}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="subject" className="block mb-1 font-mont">
                  Purpose
                </label>
                <input
                  type="text"
                  id="subject"
                  className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                  placeholder="Purpose"
                  value={formData.subject}
                  onChange={handleChange}
                />
                {errors.subject && <span className="text-red-500 text-sm">{errors.subject}</span>}
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-1 font-mont">
                  Message
                </label>
                <textarea
                  id="message"
                  className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                  rows="4"
                  placeholder="Your message here"
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:bg-gray-400"
                >
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
              </div>
              {submissionSuccess !== null && (
                <div
                  className={`mt-4 text-center text-sm ${
                    submissionSuccess ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {submissionSuccess ? "Message sent successfully!" : "Something went wrong, please try again."}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default Contact;
