import React, { useEffect, useState } from "react";
import DateTime from "./DateTime";
import AddPatients from "./AddPatients";
import Payment from "./Payment";
import CheckTests from "./CheckTests";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axiosClient from "../../axios-client";

const steps = [
  { label: "Check Tests" },
  { label: "Add Patients" },
  { label: "Date & Time" },
  { label: "Payment" },
];

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [tests, setTests] = useState([]);
  const { cart, removeFromCart } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const fetchTests = async () => {
    if (cart.length === 0) {
      setTests([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const promises = cart.map((testId) =>
        axiosClient.get(`/tests/test/${testId}`)
      );

      const results = await Promise.all(promises);

      const fetchedTests = results.map((result) => result.data.test);
      setTests(fetchedTests);
    } catch (err) {
      console.error("Error fetching tests:", err);
      setError("Failed to fetch tests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
  }, [cart]);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <CheckTests
            nextStep={nextStep}
            tests={tests}
            error={error}
            loading={loading}
          />
        );
      case 2:
        return (
          <AddPatients nextStep={nextStep} prevStep={prevStep} tests={tests} />
        );
      case 3:
        return <DateTime nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <Payment prevStep={prevStep} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto py-8 md:py-14 lg:py-20 px-4 md:px-12 lg:px-20 flex flex-col items-center justify-center">
        <div className="w-full max-w-4xl mb-8">
          <div className="flex flex-wrap items-center justify-between w-full relative">
            {steps.map((item, index) => (
              <div key={index} className="flex items-center justify-center mb-4 sm:mb-0 sm:w-1/4">
                <div className="flex flex-col items-center text-center">
                  <div
                    className={`rounded-full w-14 h-14 flex items-center justify-center font-semibold font-museo z-20 
                    ${
                      step === index + 1
                        ? "bg-card1 text-white"
                        : "border-2 border-color1 bg-white text-gray-600"
                    }
                    `}
                  >
                    {index + 1}
                  </div>
                  <span className="mt-2 font7 font-museo font-medium text-xs sm:text-sm md:text-base">
                    {item.label}
                  </span>
                </div>
              </div>
            ))}
            <div className="absolute top-6 left-5 w-[85%] sm:w-[550px] md:w-[600px] lg:w-[820px] h-1 bg-blue-800 z-10"></div>
          </div>
        </div>

        <div className="w-full max-w-screen-2xl py-6">
          <div>{renderStepContent()}</div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default MultiStepForm;
